import "./style.css";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DecisionModal from "../utils/DecisionModal";

class ContentHeader extends Component {
    /**
     * Open a window to update a file
     * @param {*} e
     */

    openDropper = (e) => {
        let files = e.target.files;
        this.props.openDropper(files);
    };
    /**
     * Open modal of a CarruselCreative
     */
    handleModalOfContentIterative = () => {
        this.props.openModal("CarrouselCreativeModal");
    };
    render() {
        return (
            <>
                <DecisionModal
                    modalID="confirmDelete"
                    text={this.props.t("sections.modal.deleteModal.warning")}
                    callbackFunction={this.confirmDelete}
                />
                <div className="  ml-0 relative border-b-2 border-gray-200 py-2 flex flex-row justify-between align-center">
                    {/* titulo */}
                    <div className=" ">
                        <span className="rounded cursor-pointer pr-3 py-6 font-extralight text-center align-middle">
                            {this.props.t("sections.content.mainTitle")}
                        </span>
                    </div>
                    <div className="flex flex-row justify-between align-center">
                        {/* boton cargar contenido */}
                        <div className="right-0 flex top-0 mt-1 mr-2 ">
                            {this.props.actualTab === "status" ? (
                                <button
                                    onClick={() => this.props.handleAddColumn()}
                                    className="group buttonNew  mx-2"
                                >
                                    <FontAwesomeIcon
                                        icon={["fas", "plus"]}
                                        className="text-white m-0 p-0"
                                    />
                                    {/* <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif, video/webm, video/mp4, video/ogg" multiple onChange={this.openDropper} class="hidden group-hover:block text-sm custom-file-input" data-before={this.props.t('sections.screens.menu.screenAddContent')}></input> */}
                                    <p
                                        className="hidden group-hover:block text-sm  custom-file-input pr-1"
                                        data-before={this.props.t(
                                            "sections.status.addNewColumn"
                                        )}
                                    ></p>
                                </button>
                            ) : (
                                <>
                                    <button
                                        onClick={() =>
                                            this.handleModalOfContentIterative()
                                        }
                                        className=" group buttonNewIterative mx-2 "
                                    >
                                        <FontAwesomeIcon
                                            icon={["fas", "clipboard-list"]}
                                            className="text-white cursor-pointer m-0 p-0"
                                        />
                                        <p
                                            className="hidden group-hover:block text-sm  custom-file-input pr-1"
                                            data-before={this.props.t(
                                                "sections.content.addContentIterative"
                                            )}
                                        ></p>
                                    </button>

                                    <button
                                        id="btnBrowseFile"
                                        className="buttonNew group mx-2"
                                    >
                                        <FontAwesomeIcon
                                            icon={["fas", "plus"]}
                                            className="align-center justift-center text-white m-0 p-0"
                                        />
                                        <label
                                            for="file"
                                            className="ml-2 inputLabel cursor-pointer"
                                        >
                                            Choose your file
                                        </label>

                                        <input
                                            type="file"
                                            name="file"
                                            id="file"
                                            accept="image/png, image/jpeg, image/jpg, image/gif, video/webm, video/mp4, video/ogg"
                                            multiple
                                            onChange={this.openDropper}
                                            className="elInput inputfile hidden group-hover:block text-sm custom-file-input"
                                            data-before={this.props.t(
                                                "sections.screens.menu.screenAddContent"
                                            )}
                                        ></input>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ContentHeader);
