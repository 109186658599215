import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line, Doughnut } from 'react-chartjs-2';
import DataTable from 'react-data-table-component-footer';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import ReportsHeader from './ReportsHeader';
import { getCustomers, getDirectImpressions, getUserData, getProgrammaticImpressionsRequests, getProgrammaticScreensRequest } from './reportRequests';
import currencyJson from '../../util_files/currency.json';
import { formatNumber } from '../../util_files/formatNumber'
import CustomPeriodModal from '../utils/CustomPeriodModal';
import cloneDeep from 'lodash.clonedeep';
import * as dotenv from 'dotenv';
import ReactTooltip from 'react-tooltip';
import Media from 'react-media';
import './reports.styles.css';

dotenv.config();

class Reports extends Component {
    //Para volver a la seccion ventas directas funcional, verificar que esten descomentadas las lineas:
    //isAtiveStats y setionInMaintenane, state
    //getDirectImpressions, componentDidMount
    //getDirectImpressions, componentDidUpdate
    //funcion setLineData, getDirectImpressionsForTable
    constructor(props) {
        super(props);
        this.state = {
            isActiveReportingGroupConsolidated: 'days',
            isActiveReportingGroupDirect: 'days',
            isActiveReportingGroupProgrammatic: 'days',
            isActiveStats: 'consolidated',
            columns: this.daysColumns,
            programmaticImpressions: [],
            directImpressions: {},
            showChart: false,
            publisherID: null,
            callConsolidated: false,
            selectedPeriod: 'lastWeek',
            showAllInSeconds: false,
            searchValue: '',
            currency: {
                name: 'ARS',
                symbol: '$'
            },
            periodToHTML: '',
            customers: [],
            groupBy: 'daily',
            customPeriodTextLabel: 'Custom Period',
            showEmptyProgrammaticHtml: false,
            dataTableIsLoading: false,
            sectionInMaintenance: [
                // {
                //     section: 'consolidated',
                //     title: this.props.t('sections.reports.sections.consolidated')
                // },
                // {
                //     section: 'direct',
                //     title: this.props.t('sections.reports.sections.directSales')
                // },
                // {
                //     section: 'programmatic',
                //     title: this.props.t('sections.reports.sections.programmatic')
                // }
            ]
        }
    }

    daysColumns = [
        { name: this.props.t('sections.reports.table.day'), selector: row => row.day, cellExport: row => row.day, sortable: true },
        { name: this.props.t('sections.reports.table.impression'), selector: row => row.impressions, cellExport: row => row.impressions, sortable: true },
        { name: this.props.t('sections.reports.table.seconds'), selector: row => row.seconds, cellExport: row => row.seconds, sortable: true, omit: true, id: 'seconds' },
        { name: 'eCPM', selector: 'ecpm', sortable: true },
        { name: this.props.t('sections.reports.table.revenue'), selector: row => row.revenue, cellExport: row => row.revenue, sortable: true }
    ];
    customersColumns = [
        { name: this.props.t('sections.reports.table.customer'), selector: row => row.customer, cellExport: row => row.customer, sortable: true },
        { name: this.props.t('sections.reports.table.impression'), selector: row => row.impressions, cellExport: row => row.impressions, sortable: true },
        { name: this.props.t('sections.reports.table.seconds'), selector: row => row.seconds, cellExport: row => row.seconds, sortable: true, omit: true, id: 'seconds' },
        { name: 'eCPM', selector: 'ecpm', sortable: true },
        { name: this.props.t('sections.reports.table.revenue'), selector: row => row.revenue, cellExport: row => row.revenue, sortable: true }
    ];
    brandsColumns = [
        { name: this.props.t('sections.reports.table.brand'), selector: row => row.brand, cellExport: row => row.brand, sortable: true },
        { name: this.props.t('sections.reports.table.impression'), selector: row => row.impressions, cellExport: row => row.impressions, sortable: true },
        { name: this.props.t('sections.reports.table.seconds'), selector: row => row.seconds, cellExport: row => row.seconds, sortable: true, omit: true, id: 'seconds' },
        { name: 'eCPM', selector: 'ecpm', sortable: true },
        { name: this.props.t('sections.reports.table.revenue'), selector: row => row.revenue, cellExport: row => row.revenue, sortable: true }
    ];
    screensColumns = [
        { name: this.props.t('sections.reports.table.screen'), selector: row => row.screen, cellExport: row => row.screen, sortable: true, grow: 2.5 },
        { name: this.props.t('sections.reports.table.impression'), selector: row => row.impressions, cellExport: row => row.impressions, sortable: true },
        { name: this.props.t('sections.reports.table.seconds'), selector: row => row.seconds, cellExport: row => row.seconds, sortable: true, omit: true, id: 'seconds' },
        { name: 'eCPM', selector: 'ecpm', sortable: true },
        { name: this.props.t('sections.reports.table.revenue'), selector: row => row.revenue, cellExport: row => row.revenue, sortable: true }
    ];
    /**
     * Get the currency from the database and if not, set the
     * default currency.
     * @return {
        *   'status': 200,
        *   'data': {
        *     'currency': 'USD',
        *     'publisher_id': '1'
        *   }
        * }
        * </code>
        */

    setDataUser = async () => {
        try {
            let response = await getUserData();
            if (response.status === 200) {
                let currencyResponse = {};
                if (response.data.currency && response.data.currency !== '-1') {
                    currencyResponse = {
                        name: response.data.currency,
                        symbol: ''
                    };
                    let currencies = currencyJson;
                    currencies.forEach(currency => {
                        if (currencyResponse.name === currency.ISO) {
                            currencyResponse.symbol = currency.symbol;
                        }
                    });
                    this.setState({ currency: currencyResponse });
                } else {
                    currencyResponse.name = cloneDeep(this.state.currency.name);
                    currencyResponse.symbol = cloneDeep(this.state.currency.symbol);
                }
                let publisherId = -1;
                if (response.data.publisher_id) {
                    publisherId = response.data.publisher_id;
                }
                this.setState({ publisherID: publisherId })
                this.setState({ showAllInSeconds: response.data.comercialize_all_by === 'seconds' })
                return currencyResponse;
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.reports.errorSetCurrency')
                });
            }
        }
        catch (err) {
            this.props.showNotification({
                type: 'error',
                text: this.props.t('common.notification.reports.dataBaseError')
            });
        }
    }
    /**
     * If the length of the array is greater than 0, then show the maintenance notification, then get
     * the direct impressions, then set the programmatic impressions for the table, then set the
     * consolidated impressions, then get the customers.
     */
    componentDidMount = async () => {
        if (this.state.sectionInMaintenance.length > 0) {
            this.showMaintenanceNotification(this.state.sectionInMaintenance);
        }
        let till = new Date();
        till = till.getFullYear().toString().slice(2, 4) + ('0' + (till.getMonth() + 1).toString()).slice(-2) + ('0' + till.getDate().toString()).slice(-2) + '23';
        this.getDirectImpressions(this.getLastWeek(), till);
        let programmaticStatus = await this.setProgrammaticImpressionsForTable(this.getLastWeek(), till, true);
        if (programmaticStatus) {
            this.setConsolidatedImpressions();
        }
        await this.getCustomers();
    }
    /**
     * It returns a promise that resolves when the state is set.
     *
     * @return A promise that resolves to the customers array.
     */
    getCustomers = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let customersResponse = await getCustomers();
                let customers = customersResponse.data;
                this.setState({ customers }, () => {
                    resolve();
                })
            } catch (error) {
                reject(error);
            }
        })
    }
    /**
     * It takes the current date, subtracts 7 days, then returns the date in the format YYMMDDHH.
     *
     * @return A string of the last week's date in the format YYMMDDHH
     */
    getLastWeek = () => {
        let now = Date.now();
        let lastWeekDate = new Date(now - (7 * 24 * 60 * 60 * 1000));
        let parsedLastWeek = lastWeekDate.getFullYear().toString().slice(2, 4) + ('0' + (lastWeekDate.getMonth() + 1).toString()).slice(-2) + ('0' + lastWeekDate.getDate().toString()).slice(-2) + ('0' + lastWeekDate.getHours().toString()).slice(-2)
        return parsedLastWeek;
    }
    componentWillUnmount = () => {
        this.setState({ dataTableIsLoading: false });
        this.props.setLoading(false);
    }
    /**
    * If the state of the component changes, then the component will update.
    *
    * @param prevProps The previous props.
    * @param prevState The previus state
    */
    componentDidUpdate = async (prevProps, prevState) => {
        let from = '';
        let till = new Date();
        till = till.getFullYear().toString().slice(2, 4) + ('0' + (till.getMonth() + 1).toString()).slice(-2) + ('0' + till.getDate().toString()).slice(-2) + ('0' + till.getHours().toString()).slice(-2);
        if (this.state.selectedPeriod === 'today') {
            from = this.getToday();
        } else if (this.state.selectedPeriod === 'yesterday') {
            from = this.getYesterday();
        }
        else if (this.state.selectedPeriod === 'lastWeek') {
            from = this.getLastWeek();
        }
        else if (this.state.selectedPeriod === 'lastMonth') {
            from = this.getLastMonth();
        }
        else if (this.state.selectedPeriod === 'custom') {
            let period = this.getCustomPeriod();
            from = period.from;
            till = period.till;
        }
        if ((Object.keys(this.state.directImpressions).length >= 1)) {
            if (prevState.selectedPeriod !== this.state.selectedPeriod) {
                this.getDirectImpressions(from, till);
            } else if (prevState.isActiveReportingGroupDirect !== this.state.isActiveReportingGroupDirect) {
                this.getDirectImpressionsForTable();
            }
        }
        if ((prevState.isActiveReportingGroupProgrammatic !== this.state.isActiveReportingGroupProgrammatic) || prevState.selectedPeriod !== this.state.selectedPeriod) {
            let programmaticStatus = await this.setProgrammaticImpressionsForTable(from, till, true);
            if (programmaticStatus) {
                this.setConsolidatedImpressions();
            }

        }
        if (this.state.isActiveStats === 'consolidated' && (prevState.isActiveReportingGroupConsolidated !== this.state.isActiveReportingGroupConsolidated || prevState.groupBy !== this.state.groupBy)) {
            this.setConsolidatedImpressions();
        }
        if (this.state.isActiveStats === 'programmatic' && prevState.groupBy !== this.state.groupBy) {
            this.setProgrammaticImpressionsForTable(from, till, false);
        }
        if (this.state.isActiveStats === 'direct' && prevState.groupBy !== this.state.groupBy) {
            this.getDirectImpressionsForTable();
        }
        if (prevState.isActiveStats !== this.state.isActiveStats && this.state.isActiveStats === 'programmatic' && prevState.groupBy === this.state.groupBy) {
            this.setProgrammaticImpressionsForTable(from, till, true);
        }
        if (this.state.matches !== prevState.matches) {
            this.setState({ matches: this.state.matches });
        }
    }
    /**
     * Get the total values of the rows by the type of
     * the impression.
     */
    changeTotalRow = () => {
        let statActive = cloneDeep(this.state.isActiveStats);
        if (statActive === 'consolidated') {
            this.getTotalValuesOfRows(this.getTypeOfConsolidatedImpression(), this.state.isActiveReportingGroupConsolidated.slice(0, -1));
        } else if (statActive === 'programmatic') {
            this.getTotalValuesOfRows(this.getTypeOfProgrammaticImpression(), this.state.isActiveReportingGroupProgrammatic.slice(0, -1));
        } else if (statActive === 'direct') {
            this.getTotalValuesOfRows(cloneDeep(this.state.directImpressionsForTable), this.state.isActiveReportingGroupDirect.slice(0, -1));
        }
    }
    /**
     * It obtains direct and programmatic impressions, depending on which submenu is displayed (Brands, Days, Screens)
     * the data needed by the table will be built, the totals will be calculated and the line and donut graphs will be made.
     * The table information will be set in the state.
     */
    setConsolidatedImpressions = () => {
        let consolidatedType = cloneDeep(this.state.isActiveReportingGroupConsolidated);
        if (this.state.isActiveStats === 'consolidated') {
            if (consolidatedType === 'days') {
                let directImpressions = cloneDeep(this.state.directImpressions).screensImpressions;
                let parsedDayImpressions = this.parseDirectImpressionsByDaysForTable(directImpressions);
                let programmaticImpressions = cloneDeep(this.state.programmaticImpressionsDays)
                let mergedImpressionsDays = this.mergeImpressions(parsedDayImpressions, programmaticImpressions, 'day');
                this.setState({ consolidatedImpressionsDays: mergedImpressionsDays }, () => {
                    this.getTotalValuesOfRows(mergedImpressionsDays, 'day');
                    this.createAndSetDataChart(mergedImpressionsDays, 'days', 'consolidated');
                    this.daysColumns[2].omit = true;
                    this.setState({ columns: this.daysColumns });
                    this.setLineDataConsolidated(parsedDayImpressions, programmaticImpressions, mergedImpressionsDays, 'days');
                });
            }
            else if (consolidatedType === 'brands') {
                let directImpressions = cloneDeep(this.state.directImpressions).screensImpressions;
                let parsedBrandImpressions = this.parseDirectImpressionsByBrandForTable(directImpressions);
                let programmaticImpressions = cloneDeep(this.state.programmaticImpressionsBrands);
                let mergedImpressionsBrands = this.mergeImpressions(parsedBrandImpressions, programmaticImpressions, 'brand');
                this.setState({ consolidatedImpressionsBrands: mergedImpressionsBrands }, () => {
                    this.getTotalValuesOfRows(mergedImpressionsBrands, 'brand');
                    if (this.state.isActiveStats === 'consolidated') {
                        this.createAndSetDataChart(mergedImpressionsBrands, 'brands', 'consolidated');
                        this.brandsColumns[2].omit = true;
                        this.setState({ columns: this.brandsColumns });
                    }
                });
            }
            else if (consolidatedType === 'screens') {
                let directImpressions = cloneDeep(this.state.directImpressions).screensImpressions;
                let parsedScreenImpressions = this.parseDirectImpressionsByScreenForTable(directImpressions);
                let programmaticImpressions = cloneDeep(this.state.programmaticImpressionsScreens);
                let mergedImpressionsScreens = this.mergeImpressions(parsedScreenImpressions, programmaticImpressions, 'screen');
                this.setState({ consolidatedImpressionsScreens: mergedImpressionsScreens }, () => {
                    this.getTotalValuesOfRows(mergedImpressionsScreens, 'screen');
                    if (this.state.isActiveStats === 'consolidated') {
                        this.createAndSetDataChart(mergedImpressionsScreens, 'screens', 'consolidated');
                        this.screensColumns[2].omit = true;
                        this.setState({ columns: this.screensColumns });
                    };
                });
            }

        }
    }
    /**
     * The dayInYear function returns the day of the year for a given date.
     *
     * @param date The date object to get the day of the year for.
     * @return The number of days since the start of the year.
     */
    dayInYear = (date) => {
        return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
    }
    /**
     * It takes in 3 arrays of objects, and a string. It then sorts the arrays by date, and then
     * creates a new array of objects that contains the sum of the impressions for each day.
     * Create the information to render the line graph that belongs to the consolidated table.
     *
     * @param directData [{day: '01/01/20', impressions: '100'}, {day: '02/01/20', impressions: '200'}]
     * @param programmaticData [{day: '01/01/20', impressions: '100'}, {day: '01/02/20', impressions:
     * '200'}]
     * @param mergedData [{day: '01/01/20', impressions: '100'}, {day: '01/02/20', impressions: '200'},
     * {day: '01/03/20', impressions: '300'}, {day: '01/04/20', impressions: '400
     * @param type 'daily' or 'weekly'
     * @return The data is being returned as an array of objects.
     */
    setLineDataConsolidated = (directData = [], programmaticData = [], mergedData = [], type) => {
        directData.sort((a, b) => {
            let aArray = a.day.split('/');
            let bArray = b.day.split('/');
            let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
            let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
            return aDate - bDate;
        });
        programmaticData.sort((a, b) => {
            let aArray = a.day.split('/');
            let bArray = b.day.split('/');
            let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
            let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
            return aDate - bDate;
        });
        mergedData.sort((a, b) => {
            let aArray = a.day.split('/');
            let bArray = b.day.split('/');
            let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
            let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
            return aDate - bDate;
        });
        let timelineOptions = {
            maintainAspectRatio: true,
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: false
                        },
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        gridLines: {
                            display: false
                        },
                    },
                ],
            },
        }
        let showType = this.state.groupBy;
        if (showType === 'daily') {
            timelineOptions.scales.xAxes = [{
                afterTickToLabelConversion: function (data) {
                    let ticks = cloneDeep(data.ticks);
                    ticks.sort((a, b) => {
                        let aArray = a.split('/');
                        let bArray = b.split('/');
                        let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
                        let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
                        return aDate - bDate;
                    });
                    var xLabels = ticks;
                    xLabels.forEach(function (labels, i) {
                        if (i % 6 !== 0) {
                            xLabels[i] = '';
                        }
                    });
                }
            }]
        } else if (showType === 'weekly') {
            timelineOptions.scales.xAxes = [{
                afterTickToLabelConversion: function (data) {
                    let ticks = cloneDeep(data.ticks);
                    ticks.sort((a, b) => {
                        let aArray = a.split('/');
                        let bArray = b.split('/');
                        let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
                        let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
                        return aDate - bDate;
                    });
                    var xLabels = ticks;
                    let arrOfMonths = [];
                    xLabels.forEach((labels, i) => {
                        let parsedLabel = labels.split('-');
                        let startDate = parsedLabel[0].replaceAll(' ', '');
                        startDate = new Date('20' + startDate.split('/')[2], startDate.split('/')[1] - 1, startDate.split('/')[0]);
                        let month = startDate.toLocaleString('default', { month: 'long' });
                        let year = startDate.getFullYear();
                        month = month[0].toUpperCase() + month.substr(1);
                        if (!arrOfMonths.includes(month)) {
                            arrOfMonths.push(month);
                            xLabels[i] = month + ' ' + year;
                        } else {
                            xLabels[i] = '';
                        }

                    });
                }
            }]
        }
        let dataTimeLineConsolidated = {
            labels: [],
            datasets: [
                {
                    label: this.props.t('sections.reports.directImpressions'),
                    data: [],
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgba(255, 99, 132, 0.7)',
                    yAxisID: 'y-axis-1',
                }
                , {
                    label: this.props.t('sections.reports.programmaticImpressions'),
                    data: [],
                    fill: true,
                    backgroundColor: 'rgba(54, 162, 235, 0.3)',
                    borderColor: 'rgba(54, 162, 235, 0.2)',
                    yAxisID: 'y-axis-2',
                }]
        }
        if (directData === undefined || programmaticData === undefined)
            return
        if (showType === 'weekly') {
            let weeks = [];
            mergedData.forEach((merged, i) => {
                if (i % 7 === 0 || i === mergedData.length - 1) {
                    weeks.push(merged.day);
                } else {
                    weeks.push(' ');
                }
            });
            let weeklyRanges = [];
            weeks.forEach((data, i) => {
                if (data !== ' ') {
                    weeklyRanges.forEach(obj => {
                        if (obj.start) {
                            if (obj.end === '') {
                                let dataParsed = data.split('/');
                                let parsedDate = new Date('20' + dataParsed[2], dataParsed[1] - 1, dataParsed[0]);
                                let startParsed = obj.start.split('/');
                                let startDate = new Date('20' + startParsed[2], startParsed[1] - 1, startParsed[0]);
                                if (this.dayInYear(parsedDate) - this.dayInYear(startDate) === 7)
                                    parsedDate.setDate(parsedDate.getDate() - 1);
                                let parsedEndDate = parsedDate.getDate() + '/' + ('0' + (parsedDate.getMonth() + 1).toString()).slice(-2) + '/' + parsedDate.getFullYear().toString().slice(2, 4);
                                obj.end = parsedEndDate;
                            }
                        }
                    })
                    let obj = {
                        start: data,
                        end: '',
                        impressionsDirect: 0,
                        impressionsProgrammatic: 0
                    }
                    if (i === weeks.length - 1) {
                        if (weeklyRanges.find(obj => data === obj.end) === undefined)
                            obj.end = data;
                    }
                    weeklyRanges.push(obj);
                }
            });
            weeklyRanges.forEach((obj, i) => {
                if (obj.end === '') {
                    let index = weeklyRanges.indexOf(obj);
                    weeklyRanges.splice(index, 1);
                }
            });
            directData.forEach((direct, i) => {
                let parsedDirectDay = direct.day.split('/');
                let directDate = new Date(parsedDirectDay[2], parsedDirectDay[1] - 1, parsedDirectDay[0]);
                weeklyRanges.forEach(range => {
                    let parsedRangeStart = range.start.split('/');
                    let parsedRangeEnd = range.end.split('/');
                    let rangeStart = new Date(parsedRangeStart[2], parsedRangeStart[1] - 1, parsedRangeStart[0]);
                    let rangeEnd = new Date(parsedRangeEnd[2], parsedRangeEnd[1] - 1, parsedRangeEnd[0]);
                    if (directDate >= rangeStart && directDate <= rangeEnd) {
                        range.impressionsDirect += Number(direct.impressions);
                    }
                })
            });
            programmaticData.forEach(programmatic => {
                let parsedProgrammaticDay = programmatic.day.split('/');
                let programmaticDate = new Date(parsedProgrammaticDay[2], parsedProgrammaticDay[1] - 1, parsedProgrammaticDay[0]);
                weeklyRanges.forEach(range => {
                    let parsedRangeStart = range.start.split('/');
                    let parsedRangeEnd = range.end.split('/');
                    let rangeStart = new Date(parsedRangeStart[2], parsedRangeStart[1] - 1, parsedRangeStart[0]);
                    let rangeEnd = new Date(parsedRangeEnd[2], parsedRangeEnd[1] - 1, parsedRangeEnd[0]);
                    if (programmaticDate >= rangeStart && programmaticDate <= rangeEnd) {
                        range.impressionsProgrammatic += Number(programmatic.impressions);
                    }
                })
            });
            weeklyRanges.forEach(range => {
                dataTimeLineConsolidated.labels.push(range.start + ' - ' + range.end);
                dataTimeLineConsolidated.datasets[0].data.push(range.impressionsDirect);
                dataTimeLineConsolidated.datasets[1].data.push(range.impressionsProgrammatic);
            });
        } else if (showType === 'daily') {
            let days = [];
            mergedData.forEach(merged => {
                dataTimeLineConsolidated.labels.push(merged.day);
                days.push(merged.day);
            });
            days.forEach(day => {
                let directImpression = directData.find(direct => direct.day === day);
                if (directImpression) {
                    dataTimeLineConsolidated.datasets[0].data.push(directImpression.impressions);
                } else {
                    dataTimeLineConsolidated.datasets[0].data.push(0);
                }
            })
            days.forEach(day => {
                let programmaticImpression = programmaticData.find(programmatic => programmatic.day === day);
                if (programmaticImpression) {
                    dataTimeLineConsolidated.datasets[1].data.push(programmaticImpression.impressions);
                } else {
                    dataTimeLineConsolidated.datasets[1].data.push(0);
                }
            })
        }
        directData.forEach(direct => {
            dataTimeLineConsolidated.datasets[0].data.push(direct.impressions);
        });
        programmaticData.forEach(programmatic => {
            dataTimeLineConsolidated.datasets[1].data.push(programmatic.impressions);
        })
        this.setState({ timelineOptionsConsolidated: timelineOptions });
        this.setState({ lineDataConsolidated: dataTimeLineConsolidated });
    }
    /**
     * It takes two arrays of objects, merges them, and returns a new array of objects.
     *
     * @param direct [{date: '2019-01-01', impressions: '100', ecpm: '0.5', revenue: '0.5'}, {date:
     * '2019-01-02', impressions: '100', ecpm: '0.5', revenue: '0.5
     * @param programmatic [{impressions: '1', ecpm: '1', revenue: '1', date: '2019-01-01'},
     * {impressions: '1', ecpm: '1', revenue: '1', date: '2019-01-02'}]
     * @param type 'date'
     * @return An array of objects.
     */
    mergeImpressions = (direct = [], programmatic = [], type) => {
        let mergedImpressions = [];
        let directImpressions = cloneDeep(direct);
        let programmaticImpressions = cloneDeep(programmatic);
        let allImpressions = programmaticImpressions.concat(directImpressions);
        allImpressions.forEach(impressions => {
            if (typeof impressions.impressions !== 'number') {
                impressions.impressions = this.parsedStringToNumber(impressions.impressions);
            }
            if (typeof impressions.ecpm !== 'number') {
                impressions.ecpm = this.parsedStringToNumber(impressions.ecpm);
            }
            if (typeof impressions.revenue !== 'number') {
                impressions.revenue = this.parsedStringToNumber(impressions.revenue);
            }
        })
        allImpressions.forEach(impression => {
            let sameDayInMergedImpression = mergedImpressions.find(mergedImpression => mergedImpression[type] === impression[type]);
            if (sameDayInMergedImpression) {
                sameDayInMergedImpression.impressions = Number(sameDayInMergedImpression.impressions) + Number(impression.impressions);
                sameDayInMergedImpression.revenue = (Number((sameDayInMergedImpression.revenue)) + Number((impression.revenue)));
                sameDayInMergedImpression.ecpm = Number((sameDayInMergedImpression.revenue / sameDayInMergedImpression.impressions) * 1000);
                sameDayInMergedImpression.merge = true;
                let indexOfSame = -1;
                mergedImpressions.forEach((impression, index) => {
                    if (sameDayInMergedImpression[type] === impression[type]) {
                        indexOfSame = index;
                    }
                });
                mergedImpressions[indexOfSame] = sameDayInMergedImpression;
            } else {
                mergedImpressions.push(impression);
            }
        });
        return mergedImpressions;

    }
    /**
     * It takes an array of impressions and a discount, and then applies the discount to each
     * impression in the array.
     *
     * @param impressions an array of objects that contain the following properties:
     * @param discount The discount to be applied to the impressions.
     */
    applyDiscountOnImpression = (impressions = [], discount) => {
        impressions.forEach((impression) => {
            impression.revenue = impression.revenue - (this.percentage(impression.revenue, discount));
            impression.ecpm = Number((impression.revenue / impression.impressions) * 1000);
        })
    }
    /**
     *  A function that takes two parameters, num and per,
     *  and returns the result of num divided by 100 multiplied by per.
     * */
    percentage = (num, per) => (num / 100) * per;
    /**
     * It takes a string that looks like ',234.56' and returns a number that looks like 1234.56.
     *
     * @param stringNumber The string number that you want to convert to a number.
     * @return A function that takes a string and returns a number.
     */
    parsedStringToNumber = (stringNumber) => {
        return Number(stringNumber.replace(this.state.currency.symbol, '').replaceAll(',', ''));
    }
    /**
     * This function takes a number, adds the currency symbol to the front of it, and then returns the
     * number as a string with commas in the appropriate places.
     *
     * @param number The number to be formatted.
     * @return The number is being returned with the currency symbol and the number is being formatted
     * to 2 decimal places.
     */
    numberToParsedString = (number) => {
        number = this.state.currency.symbol + Number(number.toFixed(2)).toLocaleString(window.navigator.language);
        return number;
    }
    /**
     * It takes an array of objects, and for each object in the array, it takes the values of the keys
     * 'ecpm', 'revenue', and 'impressions', and converts them to a string with commas in the
     * appropriate places.
     *
     *
     * @param array the array of objects that you want to parse
     * @return {
         *         'data': [
         *             {
         *                 'id': '1',
         *                 'ecpm': '1,000.00',
         *                 'revenue': '1,000.00',
         *                 'impressions': '1,000'
         *             },
         *             {
         *                 'id': '2',
         *                 'ecpm': '
         */
    parseArrayDataToParsedString = (array = []) => {
        if (array[0])
            array.forEach(data => {
                data.ecpm = this.numberToParsedString(data.ecpm)
                data.revenue = this.numberToParsedString(data.revenue)
                data.impressions = Number(data.impressions).toLocaleString(window.navigator.language)
            })
        return cloneDeep(array)
    }
    /**
     * It takes an array of objects, and for each object in the array, it takes the string values of
     * the properties 'ecpm', 'revenue', and 'impressions', and converts them to numbers.
     *
     * @param array the array of objects that you want to parse
     * @return return cloneDeep(array)
     */
    parseArrayDataToParsedNumber = (array = []) => {
        if (array[0]?.impressions)
            array.forEach(data => {
                data.ecpm = this.parsedStringToNumber(data.ecpm)
                data.revenue = this.parsedStringToNumber(data.revenue)
                data.impressions = this.parsedStringToNumber(data.impression)
            })
        return cloneDeep(array)
    }
    /**
     * It sets the state of the columns to the appropriate columns based on the state of the component.
     *
     * @param state the state of the component
     */
    setDataTableColumns = (state) => {
        switch (state) {
            case 'brands':
                this.setState({
                    columns: this.brandsColumns
                });
                break;
            case 'screens':
                this.setState({
                    columns: this.screensColumns
                });
                break;
            case 'customers':
                this.setState({
                    columns: this.customersColumns
                });
                break;
            default:
                this.setState({
                    columns: this.daysColumns
                })
                break;
        }
    }

    /**
     * It fetches programmatic impressions and returns it as an array.
     * @param {Number} from The start date for the impressions request. formatted date: YYmmddhh
     * @param {Number} till The end date for the impressions data to be retrieved. formatted date: YYmmddhh
     * @param {String} groups 'day,month,year','tag','brand'
     * @returns  If the response is successful, returns a Object with an Array of Objects. If there is an error, it displays an error notification and returns [].
     * @example Successful :[
     *   {
     *     'groups': ['23', '05', '17'],
     *     'values': [15013, 26,14987, ...],
     *     'id': '23,05,19',
     *   },
     *   {
     *     'groups': ['23', '05', '17'],
     *      'id': '23,05,19',
     *     'values': [15100, 13, 15087, ...],
     *   }, ... ]
     * @example Error: []
     */
    getProgrammaticImpressions = async (from, till, groups) => {
        let currencyResponse = await this.setDataUser();
        let currency = currencyResponse.name;
        let publisherID = this.state.publisherID;

        try {
            if (!publisherID || publisherID === '') {
                setTimeout(() => {
                    this.setState({ showEmptyProgrammaticHtml: true }, () => {
                        this.props.showNotification({
                            type: 'warning',
                            text: this.props.t('common.notification.reports.programmaticEmptyPublisherText1'),
                            text2: this.props.t('common.notification.reports.programmaticEmptyPublisherText2'),
                            link: true,
                            duration: 20000
                        });
                    });
                }, 5000);
                return [];
            }

            let programmaticImpressionResponse = await getProgrammaticImpressionsRequests(from, till, groups, publisherID, currency);

            return programmaticImpressionResponse.data.records
        }
        catch (err) {
            console.log(err);
            this.props.showNotification({
                type: 'error',
                text: this.props.t('common.notification.reports.getProgImpressionError')
            });
        }
    }

    /**
     * It takes an array of objects, sorts them by date, and then creates a new array of objects with
     * the same data, but grouped by week.
     * Create the information to render the line graph that belongs to the programmatic table.
     * @param data [{day: '01/01/20', impressions: '100'}, {day: '02/01/20', impressions: '200'}, {day:
     * '03/01/20', impressions: '300'}, {day: '04/01/20', impressions: '400
     */
    setLineDataProgrammatically = (data) => {
        data.sort((a, b) => {
            let aArray = a.day.split('/');
            let bArray = b.day.split('/');
            let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
            let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
            return aDate - bDate;
        });
        let dataTimeLine = {
            labels: [],
            datasets: [{
                label: this.props.t('sections.reports.programmaticImpressions'),
                data: [],
                fill: false,
                backgroundColor: 'rgba(54, 162, 235, 0.3)',
                borderColor: 'rgba(54, 162, 235, 0.2)',
                yAxisID: 'y-axis-2',
            }]
        }
        let timelineOptions = {
            maintainAspectRatio: true,
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-2',
                        gridLines: {
                            display: false
                        },
                    }
                ],
            },
        }
        let showType = this.state.groupBy;
        if (showType === 'daily') {
            timelineOptions.scales.xAxes = [{
                afterTickToLabelConversion: function (data) {
                    let ticks = cloneDeep(data.ticks);
                    ticks.sort((a, b) => {
                        let aArray = a.split('/');
                        let bArray = b.split('/');
                        let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
                        let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
                        return aDate - bDate;
                    });
                    var xLabels = ticks;

                    xLabels.forEach(function (labels, i) {
                        if (i % 6 !== 0) {
                            xLabels[i] = '';
                        }
                    });
                }
            }]
        } else if (showType === 'weekly') {
            timelineOptions.scales.xAxes = [{
                afterTickToLabelConversion: function (data) {
                    let ticks = cloneDeep(data.ticks);
                    ticks.sort((a, b) => {
                        let aArray = a.split('/');
                        let bArray = b.split('/');
                        let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
                        let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
                        return aDate - bDate;
                    });
                    var xLabels = ticks;
                    let arrOfMonths = [];
                    xLabels.forEach((labels, i) => {
                        let parsedLabel = labels.split('-');
                        let startDate = parsedLabel[0].replaceAll(' ', '');
                        startDate = new Date('20' + startDate.split('/')[2], startDate.split('/')[1] - 1, startDate.split('/')[0]);
                        let month = startDate.toLocaleString('default', { month: 'long' });
                        let year = startDate.getFullYear();
                        month = month[0].toUpperCase() + month.substr(1);
                        if (!arrOfMonths.includes(month)) {
                            arrOfMonths.push(month);
                            xLabels[i] = month + ' ' + year;
                        } else {
                            xLabels[i] = '';
                        }

                    });
                }
            }]
        }
        if (data === undefined)
            return
        if (showType === 'weekly') {
            let weeks = [];
            data.forEach((programmatic, i) => {
                if (i % 7 === 0 || i === data.length - 1) {
                    weeks.push(programmatic.day);
                } else {
                    weeks.push(' ');
                }
            });
            let weeklyRanges = [];
            weeks.forEach((data, i) => {
                if (data !== ' ') {
                    weeklyRanges.forEach(obj => {
                        if (obj.start) {
                            if (obj.end === '') {
                                let dataParsed = data.split('/');
                                let parsedDate = new Date('20' + dataParsed[2], dataParsed[1] - 1, dataParsed[0]);
                                let startParsed = obj.start.split('/');
                                let startDate = new Date('20' + startParsed[2], startParsed[1] - 1, startParsed[0]);
                                if (this.dayInYear(parsedDate) - this.dayInYear(startDate) === 7)
                                    parsedDate.setDate(parsedDate.getDate() - 1);
                                let parsedEndDate = parsedDate.getDate() + '/' + ('0' + (parsedDate.getMonth() + 1).toString()).slice(-2) + '/' + parsedDate.getFullYear().toString().slice(2, 4);
                                obj.end = parsedEndDate;
                            }
                        }
                    })
                    let obj = {
                        start: data,
                        end: '',
                        impressions: 0
                    }
                    if (i === weeks.length - 1) {
                        if (weeklyRanges.find(obj => data === obj.end) === undefined)
                            obj.end = data;
                    }
                    weeklyRanges.push(obj);
                }
            });
            weeklyRanges.forEach((obj, i) => {
                if (obj.end === '') {
                    let index = weeklyRanges.indexOf(obj);
                    weeklyRanges.splice(index, 1);
                }
            });
            data.forEach((programmatic, i) => {
                let parsedProgrammaticDay = programmatic.day.split('/');
                let programmaticDate = new Date(parsedProgrammaticDay[2], parsedProgrammaticDay[1] - 1, parsedProgrammaticDay[0]);
                weeklyRanges.forEach(range => {
                    let parsedRangeStart = range.start.split('/');
                    let parsedRangeEnd = range.end.split('/');
                    let rangeStart = new Date(parsedRangeStart[2], parsedRangeStart[1] - 1, parsedRangeStart[0]);
                    let rangeEnd = new Date(parsedRangeEnd[2], parsedRangeEnd[1] - 1, parsedRangeEnd[0]);
                    if (programmaticDate >= rangeStart && programmaticDate <= rangeEnd) {
                        range.impressions += Number(programmatic.impressions);
                    }
                })
            });
            weeklyRanges.forEach(range => {
                dataTimeLine.labels.push(range.start + ' - ' + range.end);
                dataTimeLine.datasets[0].data.push(range.impressions);
            });
        } else if (showType === 'daily') {
            data.forEach(programmatic => {
                dataTimeLine.labels.push(programmatic.day);
                dataTimeLine.datasets[0].data.push(programmatic.impressions);
            });
        }
        this.setState({ timelineOptionsProgrammatic: timelineOptions });
        this.setState({ lineDataProgrammatic: dataTimeLine });
    }
    /**
     * It obtains  programmatic impressions, depending on which submenu is displayed (Brands, Days, Screens)
     * the data needed by the table will be built, the totals will be calculated and the line and donut graphs will be made.
     * The table information will be set in the state.
     *
     * @param from the start date of the data to be retrieved
     * @param till '2019-07-31'
     * @param update boolean
     * @return a boolean value.
     */
    setProgrammaticImpressionsForTable = async (from, till, update = false) => {
        let programmaticType = cloneDeep(this.state.isActiveReportingGroupProgrammatic);
        let programmaticImpressionsForTable = [];
        if (programmaticType === 'days') {
            if (this.state.programmaticImpressionsDays !== undefined && !update) {
                if (this.state.isActiveStats === 'programmatic') {
                    this.getTotalValuesOfRows(programmaticImpressionsForTable, 'day', 'programmatic');
                    this.createAndSetDataChart(this.state.programmaticImpressionsDays, 'days', 'programmatic');
                    this.setLineDataProgrammatically(this.state.programmaticImpressionsDays)
                }
                return;
            }
        }
        let responseDays = await this.getProgrammaticImpressions(from, till, 'year,month,day');
        programmaticImpressionsForTable = this.getProgrammaticImpressionsByDays(responseDays);
        this.setState({ programmaticImpressionsDays: programmaticImpressionsForTable }, () => {
            if (this.state.isActiveStats === 'programmatic' && programmaticType === 'days') {
                this.getTotalValuesOfRows(programmaticImpressionsForTable, 'day');
                this.setLineDataProgrammatically(programmaticImpressionsForTable);
                this.createAndSetDataChart(programmaticImpressionsForTable, 'days', 'programmatic');
            }
        });
        if (programmaticType === 'screens') {
            if (this.state.programmaticImpressionsScreens !== undefined && !update) {
                if (this.state.isActiveStats === 'programmatic') {
                    this.createAndSetDataChart(programmaticImpressionsForTable, 'screens', 'programmatic');
                }
                return;
            }
        }
        let screenGroups = 'tag';
        let responseScreens = await this.getProgrammaticImpressions(from, till, screenGroups);
        let screenResponse = await this.getProgrammaticScreens();
        if (screenResponse === null) {
            return;
        }
        let screenDataOfPublisher = this.parseDataOfPublisher(screenResponse, this.state.publisherID);
        programmaticImpressionsForTable = this.getProgrammaticImpressionsByScreens(responseScreens, screenDataOfPublisher);
        this.setState({ programmaticImpressionsScreens: programmaticImpressionsForTable }, () => {
            if (this.state.isActiveStats === 'programmatic' && programmaticType === 'screens') {
                this.getTotalValuesOfRows(programmaticImpressionsForTable, 'screen');
                this.createAndSetDataChart(programmaticImpressionsForTable, 'screens', 'programmatic');
            }
        });
        if (programmaticType === 'brands') {
            if (this.state.programmaticImpressionsBrands !== undefined && !update) {
                if (this.state.isActiveStats === 'programmatic') {
                    this.createAndSetDataChart(programmaticImpressionsForTable, 'brands', 'programmatic');
                }
                return;
            }
        }
        let brandGroups = 'ad';
        let responseBrands = await this.getProgrammaticImpressions(from, till, brandGroups);
        programmaticImpressionsForTable = this.getProgrammaticImpressionsByBrands(responseBrands);
        this.setState({ programmaticImpressionsBrands: programmaticImpressionsForTable }, () => {
            if (this.state.isActiveStats === 'programmatic' && programmaticType === 'brands') {
                this.getTotalValuesOfRows(programmaticImpressionsForTable, 'brand');
                this.createAndSetDataChart(programmaticImpressionsForTable, 'brands', 'programmatic');
            }
            this.setState({ callConsolidated: true });
        });
        return true;
    }

    /**
     * Function that fetch Programmatic screen from screens API
     * @param {String} publisherID SSP ID of Publisher
     * @returns If the response is successful, returns a Object with an Array of Objects. If there is an error, it displays an error notification and returns null.
     * @example Success response
     * @return [
     *      0: {id: 'tgx_5488', name: 'AR - Neuquen - METRO - Leloir y Avenida Argentina', …}
     *      1: {id: 'tgx_5524', name: 'AR - Neuquen - Av. Argentina y Roca', …}
     *  ]
     * @example Fail response
     * @return null
     */
    getProgrammaticScreens = async () => {
        try {

            let screenResponse = await getProgrammaticScreensRequest(this.state.publisherID)

            return screenResponse.data.programmaticScreens;

        } catch (error) {
            this.props.showNotification({
                type: 'error',
                text: this.props.t('common.notification.reports.getProgScreenError')
            });
            return null;
        }
    }
    /**
     * If the state of isActiveReportingGroupProgrammatic is 'days', return the state of
     * programmaticImpressionsDays. If the state of isActiveReportingGroupProgrammatic is 'screens',
     * return the state of programmaticImpressionsScreens. If the state of
     * isActiveReportingGroupProgrammatic is 'brands', return the state of
     * programmaticImpressionsBrands.
     *
     * @return the value of the state.
     */
    getTypeOfProgrammaticImpression = () => {
        if (this.state.isActiveReportingGroupProgrammatic === 'days') {
            return this.state.programmaticImpressionsDays;
        }
        if (this.state.isActiveReportingGroupProgrammatic === 'screens') {
            return this.state.programmaticImpressionsScreens;
        }
        if (this.state.isActiveReportingGroupProgrammatic === 'brands') {
            return this.state.programmaticImpressionsBrands;
        }
    }
    /**
     * If the state isActiveReportingGroupConsolidated is equal to 'days', return the state
     * consolidatedImpressionsDays. If the state isActiveReportingGroupConsolidated is equal to
     * 'screens', return the state consolidatedImpressionsScreens. If the state
     * isActiveReportingGroupConsolidated is equal to 'brands', return the state
     * consolidatedImpressionsBrands.
     * </code>
     *
     * @return the value of the state.
     */
    getTypeOfConsolidatedImpression = () => {
        if (this.state.isActiveReportingGroupConsolidated === 'days') {
            return this.state.consolidatedImpressionsDays;
        }
        if (this.state.isActiveReportingGroupConsolidated === 'screens') {
            return this.state.consolidatedImpressionsScreens;
        }
        if (this.state.isActiveReportingGroupConsolidated === 'brands') {
            return this.state.consolidatedImpressionsBrands;
        }
    }
    /**
    * Parsing the data of a publisher from a list of screens.
    */
    parseDataOfPublisher = (screens = [], publisherID) => {
        let dataOfPublisher = [];
        Object.keys(screens).forEach(key => {
            let screen = screens[key];
            if (Number(screen.publisher.id.split('_')[1]) === Number(publisherID)) {
                dataOfPublisher.push(screen);
            }
        });
        return dataOfPublisher;
    };
    /**
     * It takes an array of objects and returns an array of objects.
     *
     * @param programmaticImpressions [{}]
     * @return An array of objects.
     */
    getProgrammaticImpressionsByBrands(programmaticImpressions = []) {
        let programmaticImpressionsByBrandForTable = [];
        programmaticImpressions.forEach((impression, index) => {
            let obj = {
                id: index + '-brand',
                brand: '',
                impressions: '',
                revenue: '',
                ecpm: ''
            }
            if (impression.id.toString().startsWith(' |') || impression.id.toString().startsWith(' ')) {
                let brandSplitted = impression.id.split('|')
                obj.brand = brandSplitted[brandSplitted.length - 1].replace(' ', '');
            }
            else
                obj.brand = impression.id;
            if (obj.brand.charAt(0) === '#') {
                obj.brand = obj.brand.substring(1);
                obj.addHashTag = true;
            }
            obj.impressions = impression.values[7];
            obj.revenue = (impression.values[16]);
            if (Number(obj.impressions) === 0 && Number(obj.revenue) === 0)
                obj.ecpm = 0
            else
                obj.ecpm = Number((obj.revenue / obj.impressions) * 1000);
            programmaticImpressionsByBrandForTable.push(obj);
        });
        programmaticImpressionsByBrandForTable.sort((a, b) => {
            let nameA = a.brand.toUpperCase();
            let nameB = b.brand.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        })
        return programmaticImpressionsByBrandForTable;
    }
    getProgrammaticImpressionsByScreens = (programmaticImpressions = [], screensData) => {
        let programmaticImpressionsByScreens = [];
        programmaticImpressions.forEach((programmaticImpression, index) => {
            let obj = {
                id: programmaticImpression.id,
                screen: '',
                impressions: '',
                ecpm: '',
                revenue: '',
            };
            obj.impressions = programmaticImpression.values[7];
            obj.revenue = programmaticImpression.values[16];
            if (Number(obj.impressions) === 0 && Number(obj.revenue) === 0)
                obj.ecpm = 0
            else
                obj.ecpm = Number((obj.revenue / obj.impressions) * 1000);
            programmaticImpressionsByScreens.push(obj);
        });
        let parsedProgrammaticImpressionsForTable = [];
        programmaticImpressionsByScreens.forEach((programmaticImpression) => {
            programmaticImpression.screen = null;
            screensData.forEach(screen => {
                let screenID = screen.id.split('_')[1];
                if (screenID === programmaticImpression.id) {
                    programmaticImpression.screen = screen;
                }
            });
            if (programmaticImpression.screen !== null) {
                programmaticImpression.screen = `#${programmaticImpression.id} - ${programmaticImpression.screen.name}`;
                parsedProgrammaticImpressionsForTable.push(programmaticImpression);
            }
        });
        return parsedProgrammaticImpressionsForTable;
    }
    getProgrammaticImpressionsByDays = (programmaticImpressions = []) => {
        let programmaticImpressionsByDays = [];
        programmaticImpressions.forEach((programmaticImpression, index) => {
            let obj = {
                id: index + '-day',
                day: '',
                impressions: '',
                ecpm: '',
                revenue: '',
            };
            obj.day = programmaticImpression.groups[2] + '/' + programmaticImpression.groups[1] + '/' + programmaticImpression.groups[0];
            obj.impressions = programmaticImpression.values[7];
            obj.revenue = Number(programmaticImpression.values[16]);
            if (Number(obj.impressions) === 0 && Number(obj.revenue) === 0)
                obj.ecpm = 0
            else
                obj.ecpm = Number((obj.revenue / obj.impressions) * 1000);
            programmaticImpressionsByDays.push(obj);
        });
        let sortedImpressions = programmaticImpressionsByDays.sort((a, b) => {
            return new Date(a.day) - new Date(b.day);
        });
        return sortedImpressions;
    }
    /** Return depending state.isActiveReportingGroupDirect
     * @return {string} Type of impressions
     */
    getTypeOfImpressions = () => {
        let type = '';
        if (this.state.isActiveReportingGroupDirect === 'customers' || this.state.isActiveReportingGroupDirect === 'brands') {
            type = 'content';
        } else if (this.state.isActiveReportingGroupDirect === 'days') {
            type = 'day';
        } else if (this.state.isActiveReportingGroupDirect === 'screen') {
            type = 'screen';
        }
        return type;
    }
    getDirectImpressions = async (from, till) => {
        this.setState({ dataTableIsLoading: true });
        this.props.setLoading(true);
        const filters = {
            from: from,
            till: till,
            typeOfImpressions: this.getTypeOfImpressions()
        };
        try {
            let response = await getDirectImpressions(filters);
            if (this.directImpressionsIsEmpty(response?.data)) {
                if (this.state.isActiveStats !== 'programmatic') {
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.reports.directImpressionsEmpty')
                    });
                }
                this.setState({ directImpressions: response.data }, () => {
                    this.getDirectImpressionsForTable();
                });
                return;
            }
            this.setState({ directImpressions: response.data }, () => {
                this.getDirectImpressionsForTable();
            });
        }
        catch (err) {
            console.log(err);
            if (this.state.isActiveStats === 'direct')
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.reports.directImpressionsError'),
                    duration: 3000
                });
        }
    }
    directImpressionsIsEmpty = (impressions) => {
        let isEmpty = true;
        if (impressions) {
            impressions.screensImpressions.forEach(data => {
                if (data.impressionQuantity > 0) {
                    isEmpty = false;
                }
            })
        }
        return isEmpty;
    }
    parseDirectImpressionsByDaysForTable = (directImpressions = []) => {
        let parsedDirectImpressions = [];
        let returnedImpressions = [];
        if (directImpressions.length <= 0)
            return [];
        directImpressions.forEach((data, index) => {
            let date = data.day;
            let obj = {
                id: date + '-day',
                day: '',
                seconds: '',
                impressions: '',
                ecpm: '',
                revenue: '',
            };
            let dateToParse = date.split('-');
            let dateToAdd = dateToParse[2] + '/' + dateToParse[1] + '/' + dateToParse[0];
            obj.impressions = data.impressionQuantity;
            obj.day = dateToAdd;
            obj.seconds = Number(data.secondsQuantity);
            obj.commercializeBy = data.commercializeBy;
            if (data.commercializeBy === 'seconds' || this.state.showAllInSeconds) {
                obj.revenue = Number((Number(data.screenAdValue) * Number(data.secondsQuantity)));
                obj.ecpm = Number((Number(obj.revenue) / Number(data.secondsQuantity)) * 1000);
            } else {
                obj.revenue = Number((Number(data.screenAdValue) * Number(data.impressionQuantity)));
                obj.ecpm = Number((Number(obj.revenue) / Number(data.impressionQuantity)) * 1000);
            };
            parsedDirectImpressions.push(obj);
        });
        parsedDirectImpressions.forEach(impression => {
            let impressionSelected = returnedImpressions.find(obj => obj.day === impression.day);
            if (!impressionSelected) {
                returnedImpressions.push(impression);
            } else {
                impressionSelected.impressions = Number(impressionSelected.impressions) + Number(impression.impressions);
                impressionSelected.revenue = (Number(impressionSelected.revenue) + Number(impression.revenue));
                impressionSelected.seconds = impressionSelected.seconds + impression.seconds;
                if (impressionSelected.commercializeBy === 'seconds' || this.state.showAllInSeconds) {
                    impressionSelected.ecpm = ((Number(impressionSelected.revenue) / Number(impressionSelected.seconds)) * 1000);
                } else {
                    impressionSelected.ecpm = ((Number(impressionSelected.revenue) / Number(impressionSelected.impressions)) * 1000);
                }
            }
        });
        let sortedImpressions = returnedImpressions.sort((a, b) => {
            return new Date(a.day) - new Date(b.day);
        });
        this.createAndSetDataChart(cloneDeep(sortedImpressions), 'day', 'direct');
        return sortedImpressions;
    }
    parseDirectImpressionsByScreenForTable = (directImpressions = []) => {
        let parsedDirectImpressions = [];
        directImpressions.forEach((data, index) => {
            let obj = {
                id: data.screenName + '-screen',
                screen: data.screenName,
                impressions: '',
                seconds: 0,
                ecpm: '',
                revenue: '',
            };
            let isSameScreen = parsedDirectImpressions.find(d => d.screen === obj.screen);
            if (isSameScreen) {
                isSameScreen.impressions += Number(data.impressionQuantity);
                isSameScreen.seconds += Number(data.secondsQuantity);
                if (isSameScreen.commercializeBy === 'seconds' || this.state.showAllInSeconds) {
                    isSameScreen.revenue = Number(Number(data.screenAdValue) * Number(isSameScreen.seconds));
                    isSameScreen.ecpm = Number((Number(isSameScreen.revenue) / Number(isSameScreen.seconds)) * 1000);
                } else {
                    isSameScreen.revenue = Number(Number(data.screenAdValue) * Number(isSameScreen.impressions));
                    isSameScreen.ecpm = Number((Number(isSameScreen.revenue) / Number(isSameScreen.impressions)) * 1000);
                }
            } else {
                obj.impressions = Number(data.impressionQuantity);
                obj.seconds = Number(data.secondsQuantity);
                if (data.commercializeBy === 'seconds' || this.state.showAllInSeconds) {
                    obj.revenue = Number(Number(data.screenAdValue) * Number(data.secondsQuantity));
                    obj.ecpm = Number((Number(obj.revenue) / Number(data.secondsQuantity)) * 1000);
                } else {
                    obj.revenue = Number(Number(data.screenAdValue) * Number(data.impressionQuantity));
                    obj.ecpm = Number((Number(obj.revenue) / Number(data.impressionQuantity)) * 1000);
                }
                parsedDirectImpressions.push(obj);
            }
        });
        let sortedImpressions = parsedDirectImpressions.sort((a, b) => {
            return a.screen - b.screen;
        });
        this.createAndSetDataChart(sortedImpressions, 'screen', 'direct');
        return sortedImpressions;
    }
    createAndSetDataChart = (parsedImpressions = [], type, activeStat) => {
        let impressionsDataForChart = [];
        let dataChart = {
            labels: [],
            datasets: [{
                data: [],
                backgroundColor: []
            }],
        };
        let totalImpressions = 0;
        parsedImpressions.forEach(impression => {
            if (type === 'days') {
                let parsedDayImpression = impression.day.split('/');
                let date = new Date(parsedDayImpression[2], parsedDayImpression[1] - 1, parsedDayImpression[0]);
                let obj = {
                    name: date.getDay(),
                    impressions: impression.impressions,
                };
                totalImpressions += Number(impression.impressions);
                if (Object.keys(impressionsDataForChart).includes(obj.name.toString())) {
                    impressionsDataForChart[obj.name] += Number(obj.impressions);
                } else {
                    impressionsDataForChart[obj.name] = Number(obj.impressions);
                }
            }
            if (type === 'screens') {
                let obj = {
                    name: impression.screen,
                    impressions: impression.impressions,
                }
                totalImpressions += Number(impression.impressions);
                impressionsDataForChart.push(obj);
            }
            if (type === 'brands') {
                let obj = {
                    name: impression.brand,
                    impressions: impression.impressions,
                }
                totalImpressions += Number(impression.impressions);
                impressionsDataForChart.push(obj);
            }
            if (type === 'customers') {
                let obj = {
                    name: impression.customer,
                    impressions: impression.impressions,
                }
                totalImpressions += Number(impression.impressions)
                impressionsDataForChart.push(obj);
            }
        });
        if (type === 'screens') {
            impressionsDataForChart.forEach(impression => {
                let percentage = ((Number(impression.impressions) / totalImpressions) * 100).toFixed(2);
                dataChart.datasets[0].data.push(percentage);
                dataChart.labels.push(impression.name);
                dataChart.datasets[0].backgroundColor.push('#' + Math.floor(Math.random() * 16777215).toString(16));
            });
        }
        if (type === 'days') {
            impressionsDataForChart.forEach((day, i) => {
                let percentage = ((Number(day) / totalImpressions) * 100).toFixed(2);
                dataChart.datasets[0].data.push(percentage);
                dataChart.labels.push(this.getDayName(i));
                dataChart.datasets[0].backgroundColor.push('#' + Math.floor(Math.random() * 16777215).toString(16));
            });
        }
        if (type === 'customers') {
            impressionsDataForChart.forEach((customer, i) => {
                let percentage = ((Number(customer.impressions) / totalImpressions) * 100).toFixed(2);
                dataChart.datasets[0].data.push(percentage);
                dataChart.labels.push(customer.name);
                dataChart.datasets[0].backgroundColor.push('#' + Math.floor(Math.random() * 16777215).toString(16));
            })
        }
        if (type === 'brands') {
            let brandsForPieChart = [];
            impressionsDataForChart.forEach((brand, i) => {
                let brandName = brand.name.replaceAll(' ', '').split('|');
                let brandNameParsed = '';
                if (brandName.length === 3) {
                    brandNameParsed = brandName[1] + ' | ' + brandName[2];
                } else if (brandName.length === 2) {
                    brandNameParsed = brandName[0] + ' | ' + brandName[1];
                } else if (brandName.length === 1) {
                    brandNameParsed = brandName[0];
                }
                let obj = {
                    name: brandNameParsed,
                    impressions: brand.impressions,
                }
                let sameObj = brandsForPieChart.find(b => b.name === obj.name);
                if (sameObj) {
                    sameObj.impressions = Number(sameObj.impressions) + Number(obj.impressions);
                } else {
                    brandsForPieChart.push(obj);
                }

            });
            brandsForPieChart.forEach(brand => {
                let percentage = ((Number(brand.impressions) / totalImpressions) * 100).toFixed(2);
                dataChart.datasets[0].data.push(percentage);
                dataChart.labels.push(brand.name);
                dataChart.datasets[0].backgroundColor.push('#' + Math.floor(Math.random() * 16777215).toString(16));
            });
        }
        if (activeStat === 'programmatic') {
            this.setState({ dataLabelsForChartProgrammatic: dataChart }, () => {
                this.setState({ showChart: true });
            })
        }
        else if (activeStat === 'direct') {
            this.setState({ dataLabelsForChartDirect: dataChart }, () => {
                this.setState({ showChart: true });
            })
        }
        else if (activeStat === 'consolidated') {
            this.setState({ dataLabelsForChartConsolidated: dataChart }, () => {
                this.setState({ showChart: true });
            })
        }
    }
    getDayName = (day) => {
        switch (day) {
            case 0:
                return this.props.t('common.days.sunday');
            case 1:
                return this.props.t('common.days.monday');
            case 2:
                return this.props.t('common.days.tuesday');
            case 3:
                return this.props.t('common.days.wednesday');
            case 4:
                return this.props.t('common.days.thursday');
            case 5:
                return this.props.t('common.days.friday');
            case 6:
                return this.props.t('common.days.saturday');
            default:
                return null;
        }
    }
    parseDirectImpressionsByBrandForTable = (directImpressions = []) => {
        let parsedDirectImpressions = [];
        let dataByBrands = [];
        let screensWithAdValue = [];
        let customers = cloneDeep(this.state.customers);
        directImpressions.forEach((data, index) => {
            let screenWithAdValue = {
                id: data.screen_id,
                value: data.screenAdValue || 1,
                commercializeBy: data.commercializeBy
            }
            screensWithAdValue.push(screenWithAdValue);
            let brand = data.contentBrand;
            let objBrand = {
                id: brand,
                name: data.contentBrandName,
                impressions: Number(data.impressionQuantity),
                seconds: Number(data.secondsQuantity),
                screenImpressions: {}
            }
            objBrand.screenImpressions[data.screen_id] = { impressions: Number(data.impressionQuantity), seconds: Number(data.secondsQuantity) };
            if (!Object.keys(dataByBrands).includes(brand)) {
                dataByBrands[brand] = objBrand;
            } else {
                dataByBrands[brand].impressions += Number(objBrand.impressions);
                dataByBrands[brand].seconds += Number(objBrand.seconds);
                if (!Object.keys(dataByBrands[brand].screenImpressions).includes(data.screen_id)) {
                    dataByBrands[brand].screenImpressions[data.screen_id] = {
                        impressions: Number(data.impressionQuantity),
                        seconds: Number(data.secondsQuantity)
                    };
                }
                else {
                    dataByBrands[brand].screenImpressions[data.screen_id].impressions += objBrand.screenImpressions[data.screen_id].impressions;
                    dataByBrands[brand].screenImpressions[data.screen_id].seconds += objBrand.screenImpressions[data.screen_id].seconds;
                }
            }
        });
        Object.keys(dataByBrands).forEach(brand => {
            let dataBrand = dataByBrands[brand];
            let obj = {
                id: dataBrand.id,
                brand: dataBrand.name,
                impressions: Number(dataBrand.impressions),
                seconds: Number(dataBrand.seconds),
                ecpm: '',
                revenue: ''
            }
            let brandRevenue = 0;
            Object.keys(dataBrand.screenImpressions).forEach(screen_id => {
                let impressionsQuantity = dataBrand.screenImpressions[screen_id].impressions;
                let secondsQuantity = dataBrand.screenImpressions[screen_id].seconds;
                let screen = screensWithAdValue.find(screen => screen.id === screen_id);
                obj.commercializeBy = screen.commercializeBy;
                if (screen.commercializeBy === 'seconds' || this.state.showAllInSeconds) {
                    brandRevenue += Number(secondsQuantity) * Number(screen.value);
                } else {
                    brandRevenue += Number(impressionsQuantity) * Number(screen.value);
                }
            })
            let customerFinded = customers.find(c => c.brands.includes(obj.id));
            if (customerFinded) {
                obj.revenue = Number(brandRevenue) - (this.percentage(Number(brandRevenue), Number(customerFinded.discount)));
            } else {
                obj.revenue = Number(brandRevenue);
            }
            if (obj.commercializeBy === 'seconds' || this.state.showAllInSeconds) {
                obj.ecpm = Number((Number(obj.revenue) / Number(obj.seconds)) * 1000);
            } else {
                obj.ecpm = Number((Number(obj.revenue) / Number(obj.impressions)) * 1000);
            }
            parsedDirectImpressions.push(obj);
        });
        let sortedImpressions = parsedDirectImpressions.sort((a, b) => {
            return a.brand - b.brand;
        })
        this.createAndSetDataChart(sortedImpressions, 'brand', 'direct');
        return sortedImpressions;
    }
    parseDirectImpressionsByCustomerForTable = (directImpressions) => {
        let parsedDirectImpressions = [];
        let dataByCustomer = [];
        let screensWithAdValue = [];
        let customers = cloneDeep(this.state.customers);
        directImpressions.forEach((data, index) => {
            let screenWithAdValue = {
                id: data.screen_id,
                value: Number(data.screenAdValue),
                commercializeBy: data.commercializeBy
            };

            screensWithAdValue.push(screenWithAdValue);
            let customer = data.contentCustomer;
            let objCustomer = {
                id: customer,
                name: data.contentCustomerName,
                impressions: Number(data.impressionQuantity),
                seconds: Number(data.secondsQuantity),
                screenImpressions: {}
            };
            objCustomer.screenImpressions[data.screen_id] = { impressions: Number(data.impressionQuantity), seconds: Number(data.secondsQuantity) };
            if (!Object.keys(dataByCustomer).includes(customer)) {
                dataByCustomer[customer] = objCustomer;
            } else {
                dataByCustomer[customer].impressions += Number(objCustomer.impressions);
                dataByCustomer[customer].seconds += Number(objCustomer.seconds);
                if (!Object.keys(dataByCustomer[customer].screenImpressions).includes(data.screen_id)) {
                    dataByCustomer[customer].screenImpressions[data.screen_id] = {
                        impressions: Number(data.impressionQuantity),
                        seconds: Number(data.secondsQuantity)
                    };
                }
                else {
                    dataByCustomer[customer].screenImpressions[data.screen_id].impressions += objCustomer.screenImpressions[data.screen_id].impressions;
                    dataByCustomer[customer].screenImpressions[data.screen_id].seconds += objCustomer.screenImpressions[data.screen_id].seconds;
                }
            }
        })
        Object.keys(dataByCustomer).forEach(customer => {
            let dataCustomer = dataByCustomer[customer];
            let obj = {
                id: dataCustomer.id,
                customer: dataCustomer.name,
                impressions: Number(dataCustomer.impressions),
                seconds: Number(dataCustomer.seconds),
                ecpm: '',
                revenue: ''
            }
            let customerRevenue = 0;
            Object.keys(dataCustomer.screenImpressions).forEach(screen_id => {
                let impressionsQuantity = dataCustomer.screenImpressions[screen_id].impressions;
                let secondsQuantity = dataCustomer.screenImpressions[screen_id].seconds;
                let screen = screensWithAdValue.find(screen => screen.id === screen_id);
                obj.commercializeBy = screen.commercializeBy;
                if (screen.commercializeBy === 'seconds' || this.state.showAllInSeconds) {
                    customerRevenue += Number(secondsQuantity) * Number(screen.value);
                } else {
                    customerRevenue += Number(impressionsQuantity) * Number(screen.value);
                }
            });
            let customerFinded = customers.find(c => c._id === obj.id);
            if (customerFinded) {
                obj.revenue = Number(customerRevenue) - (this.percentage(Number(customerRevenue), Number(customerFinded.discount)));
            } else {
                obj.revenue = Number(customerRevenue);
            }
            if (obj.commercializeBy === 'seconds' || this.state.showAllInSeconds) {
                obj.ecpm = Number((Number(obj.revenue) / Number(obj.seconds)) * 1000);
            } else {
                obj.ecpm = Number((Number(obj.revenue) / Number(obj.impressions)) * 1000);
            }
            parsedDirectImpressions.push(obj);
        });
        let sortedImpressions = parsedDirectImpressions.sort((a, b) => {
            return a.customer - b.customer;
        })
        this.createAndSetDataChart(sortedImpressions, 'customer', 'direct');
        return sortedImpressions;
    }

    setLineDataDirect = () => {
        let directImpressions = cloneDeep(this.state.directImpressions).screensImpressions;
        let parsedDirectImpressions = this.parseDirectImpressionsByDaysForTable(directImpressions);
        parsedDirectImpressions.sort((a, b) => {
            let aArray = a.day.split('/');
            let bArray = b.day.split('/');
            let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
            let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
            return aDate - bDate;
        });
        let dataTimeLine = {
            labels: [],
            datasets: [{
                label: this.props.t('sections.reports.directImpressions'),
                data: [],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.7)',
                yAxisID: 'y-axis-1',
            }]
        }
        let timelineOptions = {
            maintainAspectRatio: true,
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: false
                        },
                    }
                ],
            },
        }
        let showType = this.state.groupBy;
        if (showType === 'daily') {
            timelineOptions.scales.xAxes = [{
                afterTickToLabelConversion: function (data) {
                    let ticks = cloneDeep(data.ticks);
                    ticks.sort((a, b) => {
                        let aArray = a.split('/');
                        let bArray = b.split('/');
                        let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
                        let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
                        return aDate - bDate;
                    });
                    var xLabels = ticks;

                    xLabels.forEach(function (labels, i) {
                        if (i % 6 !== 0) {
                            xLabels[i] = '';
                        }
                    });
                }
            }]
        } else if (showType === 'weekly') {
            timelineOptions.scales.xAxes = [{
                afterTickToLabelConversion: function (data) {
                    let ticks = cloneDeep(data.ticks);
                    ticks.sort((a, b) => {
                        let aArray = a.split('/');
                        let bArray = b.split('/');
                        let aDate = new Date(aArray[1] + '/' + aArray[0] + '/' + aArray[2]);
                        let bDate = new Date(bArray[1] + '/' + bArray[0] + '/' + bArray[2]);
                        return aDate - bDate;
                    });
                    var xLabels = ticks;
                    let arrOfMonths = [];
                    xLabels.forEach((labels, i) => {
                        let parsedLabel = labels.split('-');
                        let startDate = parsedLabel[0].replaceAll(' ', '');
                        startDate = new Date('20' + startDate.split('/')[2], startDate.split('/')[1] - 1, startDate.split('/')[0]);
                        let month = startDate.toLocaleString('default', { month: 'long' });
                        let year = startDate.getFullYear();
                        month = month[0].toUpperCase() + month.substr(1);
                        if (!arrOfMonths.includes(month)) {
                            arrOfMonths.push(month);
                            xLabels[i] = month + ' ' + year;
                        } else {
                            xLabels[i] = '';
                        }

                    });
                }
            }]
        }
        if (parsedDirectImpressions === undefined)
            return
        if (showType === 'weekly') {
            let weeks = [];
            parsedDirectImpressions.forEach((direct, i) => {
                if (i % 7 === 0 || i === parsedDirectImpressions.length - 1) {
                    weeks.push(direct.day);
                } else {
                    weeks.push(' ');
                }
            });
            let weeklyRanges = [];
            weeks.forEach((data, i) => {
                if (data !== ' ') {
                    weeklyRanges.forEach(obj => {
                        if (obj.start) {
                            if (obj.end === '') {
                                let dataParsed = data.split('/');
                                let parsedDate = new Date('20' + dataParsed[2], dataParsed[1] - 1, dataParsed[0]);
                                let startParsed = obj.start.split('/');
                                let startDate = new Date('20' + startParsed[2], startParsed[1] - 1, startParsed[0]);
                                if (this.dayInYear(parsedDate) - this.dayInYear(startDate) === 7)
                                    parsedDate.setDate(parsedDate.getDate() - 1);
                                let parsedEndDate = parsedDate.getDate() + '/' + ('0' + (parsedDate.getMonth() + 1).toString()).slice(-2) + '/' + parsedDate.getFullYear().toString().slice(2, 4);
                                obj.end = parsedEndDate;
                            }
                        }
                    })
                    let obj = {
                        start: data,
                        end: '',
                        impressions: 0
                    }
                    if (i === weeks.length - 1) {
                        if (weeklyRanges.find(obj => data === obj.end) === undefined)
                            obj.end = data;
                    }
                    weeklyRanges.push(obj);
                }
            });
            weeklyRanges.forEach((obj, i) => {
                if (obj.end === '') {
                    let index = weeklyRanges.indexOf(obj);
                    weeklyRanges.splice(index, 1);
                }
            });
            parsedDirectImpressions.forEach((direct, i) => {
                let parsedDirectDay = direct.day.split('/');
                let directDate = new Date(parsedDirectDay[2], parsedDirectDay[1] - 1, parsedDirectDay[0]);
                weeklyRanges.forEach(range => {
                    let parsedRangeStart = range.start.split('/');
                    let parsedRangeEnd = range.end.split('/');
                    let rangeStart = new Date(parsedRangeStart[2], parsedRangeStart[1] - 1, parsedRangeStart[0]);
                    let rangeEnd = new Date(parsedRangeEnd[2], parsedRangeEnd[1] - 1, parsedRangeEnd[0]);
                    if (directDate >= rangeStart && directDate <= rangeEnd) {
                        range.impressions += Number(direct.impressions);
                    }
                })
            });
            weeklyRanges.forEach(range => {
                dataTimeLine.labels.push(range.start + ' - ' + range.end);
                dataTimeLine.datasets[0].data.push(range.impressions);
            });
        } else if (showType === 'daily') {
            parsedDirectImpressions.forEach(direct => {
                dataTimeLine.labels.push(direct.day);
                dataTimeLine.datasets[0].data.push(direct.impressions);
            });
        }
        this.setState({ timelineOptionsDirect: timelineOptions });
        this.setState({ lineDataDirect: dataTimeLine });
    }
    getDirectImpressionsForTable = () => {
        if (this.state.isActiveStats === 'direct') {
            this.setState({ dataTableIsLoading: true });
            this.props.setLoading(true);
        }
        let directImpressions = cloneDeep(this.state.directImpressions)?.screensImpressions || [];
        let directImpressionsForTable = [];
        let type = cloneDeep(this.state.isActiveReportingGroupDirect);
        if (directImpressions === undefined || directImpressions.length === 0) {
            this.props.setLoading(false);
            this.setState({ dataTableIsLoading: false });
            return [];
        } else {
            if (type === 'days') {
                directImpressionsForTable = this.parseDirectImpressionsByDaysForTable(directImpressions);
                if (this.state.isActiveStats === 'direct') {
                    this.daysColumns[2].omit = false;
                    this.setState({ columns: this.daysColumns });
                }
            };
            if (type === 'screens') {
                directImpressionsForTable = this.parseDirectImpressionsByScreenForTable(directImpressions);
                if (this.state.isActiveStats === 'direct') {
                    this.screensColumns[2].omit = false;
                    this.setState({ columns: this.screensColumns });
                }
            };
            if (type === 'brands') {
                directImpressionsForTable = this.parseDirectImpressionsByBrandForTable(directImpressions);
                if (this.state.isActiveStats === 'direct') {
                    this.brandsColumns[2].omit = false;
                    this.setState({ columns: this.brandsColumns });
                }
            };
            if (type === 'customers') {
                directImpressionsForTable = this.parseDirectImpressionsByCustomerForTable(directImpressions);
                if (this.state.isActiveStats === 'direct') {
                    this.customersColumns[2].omit = false;
                    this.setState({ columns: this.customersColumns });
                }
            };
            if (this.state.isActiveStats === 'direct')
                this.setLineDataDirect();
        }

        this.setState({ directImpressionsForTable: [] }, () => {
            this.getTotalValuesOfRows(directImpressionsForTable, type.slice(0, -1));
            this.setState({ directImpressionsForTable: directImpressionsForTable }, () => {
                this.setState({ dataTableIsLoading: false });
                this.props.setLoading(false);
                this.createAndSetDataChart(directImpressionsForTable, type, this.state.isActiveStats);
                this.setConsolidatedImpressions();
            });
        });
    }
    handleReportGroupConsolidated = (value) => {
        this.setState({ isActiveReportingGroupConsolidated: value }, () => {
            this.setDataTableColumns(this.state.isActiveReportingGroupConsolidated);
            this.clearSearchBar();
        });
    }
    handleReportGroupDirect = (value) => {
        this.setState({ isActiveReportingGroupDirect: value }, () => {
            this.setDataTableColumns(this.state.isActiveReportingGroupDirect);
            this.clearSearchBar();
        });
    }
    handleReportGroupProgrammatic = (value) => {
        this.setState({ isActiveReportingGroupProgrammatic: value }, () => {
            this.setDataTableColumns(this.state.isActiveReportingGroupProgrammatic);
            this.clearSearchBar();
        });
    }
    handleStats = (value) => {
        if (this.state.isActiveStats === value) {
            return;
        };
        this.setState({ isActiveStats: value }, () => {
            this.clearSearchBar();
            this.allGroupingByDay();
            this.setState({ groupBy: 'daily' });
            this.changeTotalRow();
            if (this.state.sectionInMaintenance.find(section => section.section === value)) {
                return;
            }
            if (value === 'direct') {
                this.daysColumns[2].omit = false;
                this.setState({ columns: [] }, () => {
                    this.setState({ columns: this.daysColumns });
                });
                this.setLineDataDirect();
                this.getDirectImpressionsForTable();
                this.createAndSetDataChart(this.state.directImpressionsForTable, 'days', 'direct');
            }
            if (value === 'consolidated') {
                this.daysColumns[2].omit = true;
                this.brandsColumns[2].omit = true;
                this.screensColumns[2].omit = true;
                this.setState({ columns: [] }, () => {
                    this.setState({ columns: this.daysColumns });
                });
            }

            if (value === 'programmatic') {
                this.setLineDataProgrammatically(this.state.programmaticImpressionsDays);
                this.createAndSetDataChart(this.state.programmaticImpressionsDays, 'days', 'programmatic');
                this.daysColumns[2].omit = true;
                this.brandsColumns[2].omit = true;
                this.screensColumns[2].omit = true;
                this.setState({ columns: [] }, () => {
                    this.setState({ columns: this.daysColumns });
                });
            }
        });

    }
    clearSearchBar = () => {
        this.setState({ searchValue: '' });
    }
    allGroupingByDay = () => {
        this.setState({ isActiveReportingGroupConsolidated: 'days' });
        this.setState({ isActiveReportingGroupDirect: 'days' });
        this.setState({ isActiveReportingGroupProgrammatic: 'days' });
    }
    getLastWeek = () => {
        let now = Date.now();
        let lastWeekDate = new Date(now - (7 * 24 * 60 * 60 * 1000));
        let parsedLastWeek = lastWeekDate.getFullYear().toString().slice(2, 4) + ('0' + (lastWeekDate.getMonth() + 1).toString()).slice(-2) + ('0' + lastWeekDate.getDate().toString()).slice(-2) + ('0' + lastWeekDate.getHours().toString()).slice(-2)
        return parsedLastWeek;
    }
    getToday = () => {
        let now = Date.now();
        let todayDate = new Date(now);
        let parsedToday = todayDate.getFullYear().toString().slice(2, 4) + ('0' + (todayDate.getMonth() + 1).toString()).slice(-2) + ('0' + todayDate.getDate().toString()).slice(-2) + '00';
        return parsedToday;
    }
    getYesterday = () => {
        let now = Date.now();
        let yesterdayDate = new Date(now - (1 * 24 * 60 * 60 * 1000));
        let parsedYesterday = yesterdayDate.getFullYear().toString().slice(2, 4) + ('0' + (yesterdayDate.getMonth() + 1).toString()).slice(-2) + ('0' + yesterdayDate.getDate().toString()).slice(-2) + '00';
        return parsedYesterday;
    }
    getLastMonth = () => {
        let now = Date.now();
        let lastMonthDate = new Date(now - (30 * 24 * 60 * 60 * 1000));
        let parsedLastMonth = lastMonthDate.getFullYear().toString().slice(2, 4) + ('0' + (lastMonthDate.getMonth() + 1).toString()).slice(-2) + ('0' + lastMonthDate.getDate().toString()).slice(-2) + ('0' + lastMonthDate.getHours().toString()).slice(-2)
        return parsedLastMonth;
    }
    getCustomPeriod = () => {
        let customPeriod = cloneDeep(this.state.customPeriodObj);
        let customDateFrom = customPeriod.from.split('-');
        customDateFrom = new Date(customDateFrom[0], customDateFrom[1] - 1, customDateFrom[2]);
        let customDateTill = customPeriod.till.split('-');
        customDateTill = new Date(customDateTill[0], customDateTill[1] - 1, customDateTill[2]);
        let parsedFrom = customDateFrom.getFullYear().toString().slice(2, 4) + ('0' + (customDateFrom.getMonth() + 1).toString()).slice(-2) + ('0' + customDateFrom.getDate().toString()).slice(-2) + ('0' + customDateFrom.getHours().toString()).slice(-2)
        let parsedTill = customDateTill.getFullYear().toString().slice(2, 4) + ('0' + (customDateTill.getMonth() + 1).toString()).slice(-2) + ('0' + customDateTill.getDate().toString()).slice(-2) + ('0' + customDateTill.getHours().toString()).slice(-2)
        return { from: parsedFrom, till: parsedTill };
    }
    onChangeSelectionGroup = (e) => {
        this.setState({ showEmptyProgrammaticHtml: false })
        let value = Number(e.target.value);
        switch (value) {
            case 1:
                this.setState({ selectedPeriod: 'today' });
                break;
            case 2:
                this.setState({ selectedPeriod: 'yesterday' })
                break;
            case 3:
                this.setState({ selectedPeriod: 'lastWeek' })
                break;
            case 4:
                this.setState({ selectedPeriod: 'lastMonth' })
                break;
            case 5:
                this.props.openModal('customPeriod')
                break;
            default:
                return null;
        }
    }
    changeField = () => {
        let activeStat = this.state.isActiveStats;
        if (activeStat === 'consolidated') {
            let consolidatedType = cloneDeep(this.state.isActiveReportingGroupConsolidated);
            return consolidatedType.substring(0, consolidatedType.length - 1);
        } else if (activeStat === 'programmatic') {
            let programmaticType = cloneDeep(this.state.isActiveReportingGroupProgrammatic);
            return programmaticType.substring(0, programmaticType.length - 1);
        } else {
            let directType = cloneDeep(this.state.isActiveReportingGroupDirect);
            return directType.substring(0, directType.length - 1);
        }
    }
    clearArray = (arr = [], clearValue) => {
        return arr.filter(value => value !== clearValue)
    };
    sortFunction = (rows = [], field, direction) => {
        field = this.changeField();
        rows = this.clearArray(rows, undefined);
        //Si field = day parsear por / y ordenar segun anio, despues mes y despues dia
        let sortedArr = [];
        let sorted = false;
        if (field === 'day') {
            let parsedRows = rows.map((row) => {
                let parsedRow = row.day.split('/');
                return {
                    date: new Date(parsedRow[1] + '/' + parsedRow[0] + '/' + parsedRow[2]),
                    value: row
                }
            });
            sortedArr = parsedRows.sort((a, b) => {
                if (a.date < b.date) return (direction === 'asc') ? -1 : 1;
                if (a.date > b.date) return (direction === 'asc') ? 1 : -1;
                return 0;
            });
            sortedArr = sortedArr.map((row) => {
                return row.value;
            });
            sorted = true;
        } else if (field === 'impressions') {
            sortedArr = rows.sort((a, b) => {
                if (Number((a.impressions).replace(',', '')) < Number((b.impressions).replace(',', ''))) return (direction === 'asc') ? -1 : 1;
                if (Number((a.impressions).replace(',', '')) > Number((b.impressions).replace(',', ''))) return (direction === 'asc') ? 1 : -1;
                return 0;
            });
            sorted = true;
        } else if (field === 'ecpm' || field === 'revenue') {
            sortedArr = rows.sort((a, b) => {
                if (Number(a[field].replace('$', '').replaceAll(',', '')) < Number(b[field].replace('$', '').replaceAll(',', ''))) return (direction === 'asc') ? -1 : 1;
                if (Number(a[field].replace('$', '').replaceAll(',', '')) > Number(b[field].replace('$', '').replaceAll(',', ''))) return (direction === 'asc') ? 1 : -1;
                return 0;
            });
            sorted = true;
        } else if (field === 'brand' || field === 'customer') {
            let indexOfNullField = -1;
            rows.forEach((row, index) => {
                if (row[field] === '*') {
                    indexOfNullField = index;
                }
            });
            let noField = -1;
            if (indexOfNullField) {
                noField = rows.splice(indexOfNullField[0], 1);
            }
            sortedArr = rows.sort((a, b) => {
                if (a[field] < b[field]) return (direction === 'asc') ? -1 : 1;
                if (a[field] > b[field]) return (direction === 'asc') ? 1 : -1;
                return 0;
            });
            sortedArr.unshift(noField[0]);
            sorted = true;
        } else if (field === 'screen') {
            sortedArr = rows.sort((a, b) => {
                if (a.screen < b.screen) return (direction === 'asc') ? -1 : 1;
                if (a.screen > b.screen) return (direction === 'asc') ? 1 : -1;
                return 0;
            });
            sorted = true;
        }
        if (sorted) {
            return this.clearArray(cloneDeep(sortedArr), undefined)
        }
        return rows;
    }
    subHeaderComponent = (isActiveStats) => {
        return <div className=''>
            <input className='border border-gray-200 rounded-sm text-gray-600 text-sm w-full ' ref={this.searchRef} id={'search-' + isActiveStats} type='text' placeholder={this.props.t('sections.reports.table.filterTable')} aria-label='Search Input' value={this.state.searchValue} onChange={this.subHeaderOnChange} />
        </div>
    }

    subHeaderOnChange = (e) => {
        let searchValue = e.target.value;
        this.setState({ searchValue: searchValue });
    };
    filteredItems = () => {
        let activeStat = cloneDeep(this.state.isActiveStats);
        let returnRows = []
        if (activeStat === 'consolidated') {
            let consolidatedType = cloneDeep(this.state.isActiveReportingGroupConsolidated);
            let consolidatedField = consolidatedType.substring(0, consolidatedType.length - 1);
            let consolidatedRows = cloneDeep(this.getTypeOfConsolidatedImpression());
            if (consolidatedRows) {
                let filteredRows = consolidatedRows.filter((row) => row[consolidatedField] && row[consolidatedField].toLowerCase().includes(this.state.searchValue.toLowerCase()));
                returnRows = filteredRows;
            }
        } else if (activeStat === 'direct') {
            let directType = cloneDeep(this.state.isActiveReportingGroupDirect);
            let directField = directType.substring(0, directType.length - 1);
            let directRows = cloneDeep(this.state.directImpressionsForTable);
            if (directRows) {
                let filteredRows = directRows.filter((row) => row[directField] && row[directField].toLowerCase().includes(this.state.searchValue.toLowerCase()));
                returnRows = filteredRows;
            }
        } else if (activeStat === 'programmatic') {
            let programmaticType = cloneDeep(this.state.isActiveReportingGroupProgrammatic);
            let programmaticField = programmaticType.substring(0, programmaticType.length - 1);
            let programmaticRows = cloneDeep(this.getTypeOfProgrammaticImpression());
            if (programmaticRows) {
                let filteredRows = programmaticRows.filter((row) => row[programmaticField] && row[programmaticField].toLowerCase().includes(this.state.searchValue.toLowerCase()));
                returnRows = filteredRows;
            }
        }
        return returnRows.filter(data => Number(data.impressions) > 0).map(data => {
            data.impressions = formatNumber(data.impressions, 'currency', null, { decimals: 0 });
            data.ecpm = formatNumber(data.ecpm, 'properCurrency', null, { currency: this.state.currency.name });
            data.revenue = formatNumber(data.revenue, 'properCurrency', null, { currency: this.state.currency.name });
            return data;
        })

    };

    getTotalValuesOfRows = (data, field) => {
        if (!data)
            return;
        let totalRevenue = 0;
        let totalEcpm = 0;
        let totalImpressions = 0;
        let totalSeconds = 0;
        data.forEach(row => {
            if (typeof row.revenue === 'number') {
                totalRevenue += row.revenue;
            }
            if (typeof row.ecpm === 'number') {
                totalEcpm += row.ecpm;
            }
            if (typeof row.impressions === 'number') {
                totalImpressions = Number(totalImpressions) + row.impressions;
            }
            if (row.hasOwnProperty('seconds')) {
                totalSeconds += row.seconds;
            };

        });
        totalEcpm = totalEcpm / data.length;
        let footer = {
            [field]: 'TOTAL',
            ecpm: formatNumber(totalEcpm, 'properCurrency', null, { currency: this.state.currency.name }),
            revenue: formatNumber(totalRevenue, 'properCurrency', null, { currency: this.state.currency.name }),
            impressions: formatNumber(totalImpressions, 'currency', null, { decimals: 0 })
        };
        if (totalSeconds > 0) {
            footer.seconds = formatNumber(totalSeconds, 'currency', null, { decimals: 0 });
        }
        this.setState({ dataTableFooter: footer });
    }
    handleGrouping = (e) => {
        let groupBy = e.target.getAttribute('data');
        this.setState({ groupBy });
    }

    setCustomPeriod = (from, till) => {
        let customPeriodObj = {
            from: from,
            till: till
        }
        this.setState({ customPeriodObj }, () => {
            this.setState({ selectedPeriod: 'custom' });
        });
    }
    parseAndSetPeriodToHTML = () => {
        let period = cloneDeep(this.state.selectedPeriod);
        let now = new Date();
        now = now.getDate() + '/' + (now.getMonth() + 1) + '/' + now.getFullYear();
        let fromToString = '';
        let from = '';
        switch (period) {
            case 'today':
                return now;
            case 'yesterday':
                from = this.getYesterday();
                from = from.substring(0, from.length - 2);
                fromToString = '';
                for (let index = 0; index < from.length; index++) {
                    if (index % 2 === 0 && index !== 0) {
                        fromToString += '/';
                    }
                    fromToString += from[index];
                }
                let fromSplited = fromToString.split('/');
                fromToString = `${fromSplited[2]}/${fromSplited[1]}/20${fromSplited[0]}`;
                return fromToString + ' ~ ' + now;
            case 'lastWeek':
                from = this.getLastWeek();
                from = from.substring(0, from.length - 2);
                fromToString = '';
                for (let index = 0; index < from.length; index++) {
                    if (index % 2 === 0 && index !== 0) {
                        fromToString += '/';
                    }
                    fromToString += from[index];
                }
                fromToString = `${fromToString.split('/')[2]}/${fromToString.split('/')[1]}/20${fromToString.split('/')[0]}`;
                return fromToString + ' ~ ' + now;
            case 'lastMonth':
                from = this.getLastMonth();
                from = from.substring(0, from.length - 2);
                fromToString = '';
                for (let index = 0; index < from.length; index++) {
                    if (index % 2 === 0 && index !== 0) {
                        fromToString += '/';
                    }
                    fromToString += from[index];
                }
                fromToString = `${fromToString.split('/')[2]}/${fromToString.split('/')[1]}/20${fromToString.split('/')[0]}`;
                return fromToString + ' ~ ' + now;
            case 'custom':
                let periodObj = cloneDeep(this.state.customPeriodObj);
                let fromPeriod = periodObj.from;
                let tillPeriod = periodObj.till;
                let tillToString = '';
                fromToString = '';
                fromPeriod = fromPeriod.split('-');
                tillPeriod = tillPeriod.split('-');
                fromToString = fromPeriod[2] + '/' + fromPeriod[1] + '/' + fromPeriod[0].substring(0, fromPeriod[0].length);
                tillToString = tillPeriod[2] + '/' + tillPeriod[1] + '/' + tillPeriod[0].substring(0, fromPeriod[0].length);
                return fromToString + ' ~ ' + tillToString;
            default:
                return null;
        }

    }

    showMaintenanceNotification = (sections) => {
        let sectionsInString = sections.map(section => section.title).toString();
        this.props.showNotification({
            type: 'warnign',
            text: this.props.t('common.maintenance').replace('_section', sectionsInString),
            duration: 5000
        });
    }

    render() {
        const isActiveReportingGroupProgrammatic = this.state.isActiveReportingGroupProgrammatic;
        const isActiveReportingGroupDirect = this.state.isActiveReportingGroupDirect;
        const isActiveReportingGroupConsolidated = this.state.isActiveReportingGroupConsolidated;
        const isActiveStats = this.state.isActiveStats;
        const sectionInMaintenance = this.state.sectionInMaintenance;
        const showEmptyProgrammaticHtml = this.state.showEmptyProgrammaticHtml;
        return (
            <>
                <ReportsHeader />
                <div className='relative w-full h-screen overflow flex py-2'>
                    <div className='w-full bg-opacity-30 bg-white p-2'>
                        <div className='flex flex-col md:flex-row space-y-2 justify-between'>
                            <div>
                                <nav className='flex flex-nowrap overflow-x-auto items-center transform'>
                                    <button id='statsConsolidated' onClick={() => this.handleStats('consolidated')} className={`${isActiveStats === 'consolidated' ? 'tabActive font-semibold' : ''} tabGeneral text-xs flex-1`}>
                                        {this.props.t('sections.reports.sections.consolidated')}
                                    </button>
                                    <button id='statsDirect' onClick={() => this.handleStats('direct')} className={`${isActiveStats === 'direct' ? 'tabActive font-semibold' : ''} tabGeneral text-xs flex-1`}>
                                        {this.props.t('sections.reports.sections.directSales')}
                                    </button>
                                    <button id='statsProgrammatic' onClick={() => this.handleStats('programmatic')} className={`${isActiveStats === 'programmatic' ? 'tabActive font-semibold' : ''} tabGeneral text-xs flex-1`}>
                                        {this.props.t('sections.reports.sections.programmatic')}
                                    </button>
                                </nav>
                            </div>
                            <div className='flex justify-between md:content-center'>
                                <div className='view-buttons border rounded bg-white px-2 pt-1.5 divide-x space-x-2 mr-4 mb-2 shadow text-xs font-light items-center justify-center text-gray-500'>
                                    {/* Los oculté: <button className='border-2 p-1 rounded-l' data='hourly' onClick={this.handleGrouping}>{this.props.t('sections.reports.hour')}</button>
                                <button className='border-2 p-1 rounded-r' data='monthly' onClick={this.handleGrouping}>{this.props.t('sections.reports.month')}</button> */}
                                    <span className={`focus:outline-none hover:text-black ${this.state.groupBy === 'daily' ? 'font-semibold text-black' : 'font-light'} duration-200 ease-in-out transition cursor-pointer text-xs md:text-sm`} data='daily' onClick={this.handleGrouping}>{this.props.t('sections.reports.day')}</span>
                                    <span className={`focus:outline-none hover:text-black ${this.state.groupBy === 'weekly' ? 'font-semibold text-black' : 'font-light'} pl-1 duration-200 ease-in-out transition cursor-pointer text-xs md:text-sm`} data='weekly' onClick={this.handleGrouping}>{this.props.t('sections.reports.week')}</span>
                                </div>
                                <ReactTooltip id='periodTooltip' className='tooltip' effect='solid'>
                                    {this.parseAndSetPeriodToHTML()}
                                </ReactTooltip>
                                <div data-tip data-for='periodTooltip' data-class='shadow-lg' data-background-color='white' data-text-color='000000' className='selected-period truncate md:break-words text-xs text-gray-700 mt-3 transform scale-90'>{this.parseAndSetPeriodToHTML()}</div>
                                <div className='period'>
                                    <div className='text-right mb-2 flex content-center'>
                                        <div><FontAwesomeIcon icon={['fad', 'calendar']} fixedWidth className='mr-2 mt-2.5 text-blue-500' /></div>
                                        <select defaultValue={3} id='ifAny' onChange={this.onChangeSelectionGroup} name='ifAny' className='focus:ring-0 focus:outline-none h-full py-2 pl-2 pr-10 border-none bg-transparent text-gray-500 text-xs md:text-sm border border-gray-200 bg-white hover:bg-gray-100 shadow rounded-sm cursor-pointer'>
                                            <option value={1}>{this.props.t('sections.reports.periods.today')}</option>
                                            <option value={2}>{this.props.t('sections.reports.periods.yesterday')}</option>
                                            <option value={3} >{this.props.t('sections.reports.periods.thisWeek')}</option>
                                            <option value={4}>{this.props.t('sections.reports.periods.monthAgo')}</option>
                                            <option value={5}>{this.props.t('sections.reports.periods.customPeriod')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-4 mb-4 bg-white rounded-b-xl shadow-lg'>
                            <div className={`${isActiveStats === 'consolidated' ? 'block' : 'hidden'}`}>
                                {sectionInMaintenance.find(s => s.section === 'consolidated') ?
                                    <>
                                        <div className='text-center align-middle'>
                                            {this.props.t('common.maintenance').replace('_section', sectionInMaintenance.find(s => s.section === 'consolidated').title)}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='font-light text-xs uppercase'>{this.props.t('sections.reports.timeline')}: <span className='font-semibold'>{this.props.t('sections.reports.sections.consolidated')}</span></div>
                                        <Media
                                            queries={{
                                                small: '(max-width: 599px)',
                                                medium: '(min-width: 600px) and (max-width: 1199px)',
                                                large: '(min-width: 1200px)'
                                            }
                                            }
                                        >
                                            {matches => (
                                                <Fragment>
                                                    {matches.small &&
                                                        <Line
                                                            width={100}
                                                            height={90}
                                                            fill={true}
                                                            data={this.state.lineDataConsolidated || {}}
                                                            options={this.state.timelineOptionsConsolidated || {}}
                                                        />
                                                    }
                                                    {matches.medium &&
                                                        <Line
                                                            width={100}
                                                            height={30}
                                                            fill={true}
                                                            data={this.state.lineDataConsolidated || {}}
                                                            options={this.state.timelineOptionsConsolidated || {}}
                                                        />
                                                    }
                                                    {matches.large &&
                                                        <Line
                                                            width={100}
                                                            height={20}
                                                            fill={true}
                                                            data={this.state.lineDataConsolidated || {}}
                                                            options={this.state.timelineOptionsConsolidated || {}}
                                                        />
                                                    }
                                                </Fragment>
                                            )}
                                        </Media>
                                    </>
                                }
                            </div>
                            <div className={`${isActiveStats === 'direct' ? 'block' : 'hidden'}`}>
                                {sectionInMaintenance.find(s => s.section === 'direct') ?
                                    <>
                                        <div className='text-center align-middle'>
                                            {this.props.t('common.maintenance').replace('_section', sectionInMaintenance.find(s => s.section === 'direct').title)}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='font-light text-xs uppercase'>{this.props.t('sections.reports.timeline')}: <span className='font-semibold'>{this.props.t('sections.reports.sections.directSales')}</span></div>
                                        <Media
                                            queries={{
                                                small: '(max-width: 599px)',
                                                medium: '(min-width: 600px) and (max-width: 1199px)',
                                                large: '(min-width: 1200px)'
                                            }
                                            }
                                        >
                                            {matches => (
                                                <Fragment>
                                                    {matches.small &&
                                                        <Line
                                                            width={100}
                                                            height={90}
                                                            fill={true}
                                                            data={this.state.lineDataDirect || {}}
                                                            options={this.state.timelineOptionsDirect || {}}
                                                        />
                                                    }
                                                    {matches.medium &&
                                                        <Line
                                                            width={100}
                                                            height={30}
                                                            fill={true}
                                                            data={this.state.lineDataDirect || {}}
                                                            options={this.state.timelineOptionsDirect || {}}
                                                        />
                                                    }
                                                    {matches.large &&
                                                        <Line
                                                            width={100}
                                                            height={20}
                                                            fill={true}
                                                            data={this.state.lineDataDirect || {}}
                                                            options={this.state.timelineOptionsDirect || {}}
                                                        />
                                                    }
                                                </Fragment>
                                            )}
                                        </Media>
                                    </>
                                }
                            </div>
                            {showEmptyProgrammaticHtml ?
                                <div className={`flex flex-col justify-center items-center h-screen ${isActiveStats === 'programmatic' ? 'block' : 'hidden'}`}>
                                    <label className='my-auto'>
                                        {this.props.t('sections.content.programmaticNotAssociated')}
                                    </label>
                                </div>
                                :
                                <div className={`${isActiveStats === 'programmatic' ? 'block' : 'hidden'}`}>
                                    {sectionInMaintenance.find(s => s.section === 'programmatic') ?
                                        <>
                                            <div className='text-center align-middle'>
                                                {this.props.t('common.maintenance').replace('_section', sectionInMaintenance.find(s => s.section === 'programmatic').title)}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='font-light text-xs uppercase'>{this.props.t('sections.reports.timeline')}: <span className='font-semibold'>{this.props.t('sections.reports.sections.programmatic')}</span></div>
                                            <Media
                                                queries={{
                                                    small: '(max-width: 599px)',
                                                    medium: '(min-width: 600px) and (max-width: 1199px)',
                                                    large: '(min-width: 1200px)'
                                                }
                                                }
                                            >
                                                {matches => (
                                                    <Fragment>
                                                        {matches.small &&
                                                            <Line
                                                                width={100}
                                                                height={90}
                                                                fill={true}
                                                                data={this.state.lineDataProgrammatic || {}}
                                                                options={this.state.timelineOptionsProgrammatic || {}}
                                                            />
                                                        }
                                                        {matches.medium &&
                                                            <Line
                                                                width={100}
                                                                height={30}
                                                                fill={true}
                                                                data={this.state.lineDataProgrammatic || {}}
                                                                options={this.state.timelineOptionsProgrammatic || {}}
                                                            />
                                                        }
                                                        {matches.large &&
                                                            <Line
                                                                width={100}
                                                                height={20}
                                                                fill={true}
                                                                data={this.state.lineDataProgrammatic || {}}
                                                                options={this.state.timelineOptionsProgrammatic || {}}
                                                            />
                                                        }
                                                    </Fragment>
                                                )}
                                            </Media>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                        {/* Menu Consolidated */}
                        <nav className={`flex ${isActiveStats === 'consolidated' ? 'block' : 'hidden'} bg-white rounded-t-md bg-opacity-50 mt-1`}>
                            {sectionInMaintenance.find(s => s.section === 'consolidated') ?
                                <>
                                </>
                                :
                                <>
                                    <button id='ReportGroupConsolidated-days' onClick={() => this.handleReportGroupConsolidated('days')} className={`${isActiveReportingGroupConsolidated === 'days' ? 'tabActive' : ''} tabGeneral`}>
                                        {this.props.t('sections.reports.byDays')}
                                    </button>
                                    <button id='ReportGroupConsolidated-brands' onClick={() => this.handleReportGroupConsolidated('brands')} className={`${isActiveReportingGroupConsolidated === 'brands' ? 'tabActive' : ''} tabGeneral`}>
                                        {this.props.t('sections.reports.byBrands')}
                                    </button>
                                    <button id='ReportGroupConsolidated-screens' onClick={() => this.handleReportGroupConsolidated('screens')} className={`${isActiveReportingGroupConsolidated === 'screens' ? 'tabActive' : ''} tabGeneral`}>
                                        {this.props.t('sections.reports.byScreens')}
                                    </button>
                                </>
                            }
                        </nav>
                        {/* Menu Programmatic */}
                        {!showEmptyProgrammaticHtml &&
                            <nav className={`flex ${isActiveStats === 'programmatic' ? 'block' : 'hidden'} bg-white rounded-t-md bg-opacity-50 mt-1`}>
                                {sectionInMaintenance.find(s => s.section === 'programmatic') ?
                                    <>
                                    </>
                                    :
                                    <>
                                        <button id='reportGroupProgrammatic-days' onClick={() => this.handleReportGroupProgrammatic('days')} className={`${isActiveReportingGroupProgrammatic === 'days' ? 'tabActive' : ''} tabGeneral`}>
                                            {this.props.t('sections.reports.byDays')}
                                        </button>
                                        <button id='reportGroupProgrammatic-brands' onClick={() => this.handleReportGroupProgrammatic('brands')} className={`${isActiveReportingGroupProgrammatic === 'brands' ? 'tabActive' : ''} tabGeneral`}>
                                            {this.props.t('sections.reports.byBrands')}
                                        </button>
                                        <button id='reportGroupProgrammatic-screens' onClick={() => this.handleReportGroupProgrammatic('screens')} className={`${isActiveReportingGroupProgrammatic === 'screens' ? 'tabActive' : ''} tabGeneral`}>
                                            {this.props.t('sections.reports.byScreens')}
                                        </button>
                                    </>
                                }
                            </nav>
                        }
                        {/* Menu Direct */}
                        <nav className={`flex ${isActiveStats === 'direct' ? 'block' : 'hidden'} bg-white rounded-t-md bg-opacity-50 mt-1`}>
                            {sectionInMaintenance.find(s => s.section === 'direct') ?
                                <>
                                </>
                                :
                                <>
                                    <button id='ReportGroupDirect-days' onClick={() => this.handleReportGroupDirect('days')} className={`${isActiveReportingGroupDirect === 'days' ? 'tabActive' : ''} tabGeneral`}>
                                        {this.props.t('sections.reports.byDays')}
                                    </button>
                                    <button id='ReportGroupDirect-customers' onClick={() => this.handleReportGroupDirect('customers')} className={`${isActiveReportingGroupDirect === 'customers' ? 'tabActive' : ''} tabGeneral`}>
                                        {this.props.t('sections.reports.byCustomers')}
                                    </button>
                                    <button id='ReportGroupDirect-brands' onClick={() => this.handleReportGroupDirect('brands')} className={`${isActiveReportingGroupDirect === 'brands' ? 'tabActive' : ''} tabGeneral`}>
                                        {this.props.t('sections.reports.byBrands')}
                                    </button>
                                    <button id='ReportGroupDirect-screens' onClick={() => this.handleReportGroupDirect('screens')} className={`${isActiveReportingGroupDirect === 'screens' ? 'tabActive' : ''} tabGeneral`}>
                                        {this.props.t('sections.reports.byScreens')}
                                    </button>
                                </>
                            }
                        </nav>
                        {!showEmptyProgrammaticHtml &&
                            <div className={`${isActiveStats === 'programmatic' ? 'block' : 'hidden'} bg-white bg-opacity-90 rounded-b-md shadow-lg flex flex-col md:flex-row w-full`}>
                                {sectionInMaintenance.find(s => s.section === 'programmatic') ?
                                    <>
                                    </>
                                    :
                                    <>
                                        <div className='w-full md:w-8/12 p-2'>
                                            {this.state.dataTableIsLoading ?
                                                <></>
                                                :
                                                <DataTableExtensions
                                                    columns={this.state.columns}
                                                    data={
                                                        this.filteredItems() || this.getTypeOfProgrammaticImpression()
                                                    }
                                                    filter={false}
                                                    exportHeaders={true}
                                                >
                                                    <DataTable
                                                        noHeader={true}
                                                        dense={true}
                                                        highlightOnHover={true}
                                                        fixedColumns={false}
                                                        fixedHeaderScrollHeight={'60vh'}
                                                        subHeader={true}
                                                        subHeaderComponent={this.subHeaderComponent('programmatic')}
                                                        scrollCollapse
                                                        sortFunction={this.sortFunction}
                                                        footer={this.state.dataTableFooter || {}}
                                                        defaultSortField='day'
                                                        defaultSortAsc={false}
                                                        pagination
                                                    />
                                                </DataTableExtensions>
                                            }
                                        </div>
                                        <div className='w-full md:w-4/12 p-2'>
                                            {this.state.showChart &&
                                                <Doughnut
                                                    width={60}
                                                    height={60}
                                                    data={this.state.dataLabelsForChartProgrammatic || {}}
                                                    options={{
                                                        legend: {
                                                            display: false
                                                        },
                                                        tooltips: {
                                                            callbacks: {
                                                                title: function (tooltipItem, data) {
                                                                    return data['labels'][tooltipItem[0]['index']];
                                                                },
                                                                label: function (tooltipItem, data) {
                                                                    return data['datasets'][0]['data'][tooltipItem['index']] + '%';
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        <div className={`${isActiveStats === 'direct' ? 'block' : 'hidden'} bg-white bg-opacity-90 rounded-b-md shadow-lg flex flex-col md:flex-row w-full`}>
                            {sectionInMaintenance.find(s => s.section === 'direct') ?
                                <>
                                </>
                                :
                                <>
                                    <div className='w-full md:w-8/12 p-2'>
                                        {this.state.dataTableIsLoading ?
                                            <></>
                                            :
                                            <DataTableExtensions
                                                columns={this.state.columns}
                                                data={
                                                    this.filteredItems() || this.state.directImpressionsForTable
                                                }
                                                filter={false}
                                                exportHeaders={true}
                                            >
                                                <DataTable
                                                    noHeader={true}
                                                    dense={true}
                                                    highlightOnHover={true}
                                                    fixedColumns={false}
                                                    fixedHeaderScrollHeight={'60vh'}
                                                    subHeader={true}
                                                    subHeaderComponent={this.subHeaderComponent('direct')}
                                                    scrollCollapse
                                                    sortFunction={this.sortFunction}
                                                    footer={this.state.dataTableFooter || {}}
                                                    defaultSortField='day'
                                                    defaultSortAsc={false}
                                                    pagination
                                                />
                                            </DataTableExtensions>
                                        }
                                    </div>
                                    <div className='w-full md:w-4/12 p-2'>
                                        {this.state.showChart &&
                                            <Doughnut
                                                width={60}
                                                height={60}
                                                data={this.state.dataLabelsForChartDirect || {}}
                                                options={{
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltips: {
                                                        callbacks: {
                                                            title: function (tooltipItem, data) {
                                                                return data['labels'][tooltipItem[0]['index']];
                                                            },
                                                            label: function (tooltipItem, data) {
                                                                return data['datasets'][0]['data'][tooltipItem['index']] + '%';
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        }
                                    </div>
                                </>
                            }

                        </div>
                        <div className={`${isActiveStats === 'consolidated' ? 'block' : 'hidden'} bg-white bg-opacity-90 rounded-b-md shadow-lg flex flex-col md:flex-row w-full`}>
                            {sectionInMaintenance.find(s => s.section === 'consolidated') ?
                                <>
                                </>
                                :
                                <>
                                    <div className='w-full md:w-8/12 p-2'>
                                        {this.state.dataTableIsLoading ?
                                            <></>
                                            :
                                            <DataTableExtensions
                                                columns={this.state.columns}
                                                data={
                                                    this.filteredItems() || this.getTypeOfConsolidatedImpression()
                                                }
                                                filter={false}
                                                exportHeaders
                                                fileName={'Reports'}
                                            >
                                                <DataTable
                                                    noHeader={true}
                                                    dense={true}
                                                    highlightOnHover={true}
                                                    fixedColumns={true}
                                                    fixedHeaderScrollHeight={'60vh'}
                                                    subHeader={true}
                                                    subHeaderComponent={this.subHeaderComponent('consolidated')}
                                                    scrollCollapse={false}
                                                    sortFunction={this.sortFunction}
                                                    footer={this.state.dataTableFooter || {}}
                                                    pagination
                                                />
                                            </DataTableExtensions>
                                        }
                                    </div>
                                    <div className='w-full md:w-4/12 p-6'>
                                        {this.state.showChart &&
                                            <Doughnut
                                                width={60}
                                                height={60}
                                                data={this.state.dataLabelsForChartConsolidated || {}}
                                                options={{
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltips: {
                                                        callbacks: {
                                                            title: function (tooltipItem, data) {
                                                                return data['labels'][tooltipItem[0]['index']];
                                                            },
                                                            label: function (tooltipItem, data) {
                                                                return data['datasets'][0]['data'][tooltipItem['index']] + '%';
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <CustomPeriodModal modalID='customPeriod' callbackFunction={this.setCustomPeriod} title={this.props.t('common.modals.customPeriod.title')} />
            </>
        );
    }
}

export default withTranslation()(Reports);
