import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import SideNavbar from '../common/SideNavbar';
import ButtonSwitcher from '../../../buttons/ButtonSwitcher';
import { isEqual } from 'lodash';

class CommercialLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveCommercialMenu: "pricing",
            screens: [],
            customers: [],
            brands: [],
            iab_categories: [],
            user: {}
        }
    }

    componentDidMount = () => {
        const { screens, customers, brands, iab_categories, user } = this.props;
        this.setState({
            screens,
            customers,
            brands,
            iab_categories,
            user
        })
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps, this.props)) {
            const { screens, customers, brands, iab_categories, user } = this.props;
            this.setState({
                screens,
                customers,
                brands,
                iab_categories,
                user
            })
        }
    }

    /* Creating a table with the following columns:
    1. Name
    2. Ad Value
    3. Currency
    4. Button that trigger a function for the row*/
    commercialScreenPricing = [
        {name: 'id', selector: row => row._id, omit: true},
        {name: this.props.t('sections.management.section.commercial.pricing.priceTable.name'), selector: row => row.name, sortable: true, width: '45%'},
        {name: this.props.t('sections.management.section.commercial.pricing.priceTable.priceFor'), selector: row => row._id,cell:(row) => <ButtonSwitcher handleVisibility={()=>{this.setCommercializeScreenBy(row._id,row.commercialize_by)}} status={this.props.statusSwicher(row.commercialize_by)} labelOn={this.props.t('sections.management.section.commercial.pricing.priceTable.second')} labelOff={this.props.t('sections.management.section.commercial.pricing.priceTable.impression')} > </ButtonSwitcher>},
        {name: this.props.t('sections.management.section.commercial.pricing.priceTable.adValue'), selector: row => row.adValue || this.props.t('sections.management.section.commercial.pricing.priceTable.noAdValue'), sortable: true},
        {name: this.props.t('sections.management.section.commercial.pricing.priceTable.currency'), selector: row => row.currency || this.props.t('sections.management.section.commercial.pricing.priceTable.noCurrency'), sortable: false},
        {
            //Hacer andar el Modal para editar datos:
            //Editar Valor de Ad Impression (es el valor de cada impresión o salida de un anuncio en esta pantalla).
            cell: (row) => <button className="buttonPrimary" raised primary onClick={(e) => {this.props.toggleCommercialScreen(row, true); this.props.openModal("editComScreenModal")}}>{this.props.t('sections.management.section.commercial.pricing.priceTable.button')}</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

      /* Creating a table with the following columns:
    1. Company
    2. Contact Name
    3. Email
    4. Phone
    5. Discount
    6. Button that gives the row the function to edit values
    7. Button that gives the function to delete the row*/
    commercialCustomersList = [
        { name: 'id', selector: row => row._id, omit: true },
        { name: this.props.t('sections.management.section.commercial.customers.customerTable.company'), selector: row => row.company, sortable: true },
        { name: this.props.t('sections.management.section.commercial.customers.customerTable.contact'), selector: row => row.contact_name, sortable: true },
        { name: this.props.t('sections.management.section.commercial.customers.customerTable.mail'), selector: row => row.email, sortable: false },
        { name: this.props.t('sections.management.section.commercial.customers.customerTable.phone'), selector: row => row.phone, sortable: false },
        { name: this.props.t('sections.management.section.commercial.customers.customerTable.discount'), selector: row => row.discount, sortable: false },
        {
            //Hacer andar el Modal para editar datos.
            //Editar Datos principales y opción de BORRAR Customer (con confirmación).
            cell: (row) => <button className="buttonSecondary-sm" raised primary onClick={() => {this.props.toggleCommercialCustomersModal(row, true); this.props.openModal("editCustomerModal")}}>{this.props.t('sections.management.section.commercial.customers.customerTable.editButton')}</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => <button className="px-3 p-1 bg-red-50" onClick={(e) => this.props.removeRow("customer", row)}><FontAwesomeIcon icon={['fas', 'trash']} className="text-red-500" /></button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];
    commercialBrandList = [
        { name: 'id', selector: row => row._id, omit: true },
        { name: this.props.t('sections.content.brand'), selector: row => row.name, sortable: true },
        {
            name: this.props.t('sections.management.section.commercial.customers.tab'),
            cell: (row) => {
                let selected = row.customer;
                return <div className='flex'>
                    {this.state.customers.find(cust => cust._id === selected)?.contact_name}
                </div>
            },
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            name: this.props.t('sections.management.section.commercial.brands.brandCategory'),
            cell: (row) => {
                let selected = row.categories?.length > 2 ? row.categories?.slice(0, 2)?.concat([{ label: '+' + (row.categories?.length - 2), value: -1 }]) : row.categories;
                return <div className='flex'>
                    {selected?.map(option => <div className='px-1 py-0.5 m-0.5 bg-gray-200 font-semibold rounded'>{option.label}</div>)}
                </div>
                /* return <Select
                    // filterOptions={customFilter}
                    className="w-full overflow-y-invisible margin-auto p-0"
                    name={row._id}
                    isMulti
                    delimiter="..."
                    isDisabled={true}
                    width="35%"
                    options={this.state.iab_categories}
                    value={selected}
                /> */
            },
            ignoreRowClick: true,
            allowOverflow: true,
        },
        {
            //Hacer andar el Modal para editar datos.
            //Editar Datos principales y opción de BORRAR Customer (con confirmación).
            cell: (row) => <button className="buttonSecondary-sm" raised primary onClick={() => { this.props.toggleCommercialCustomersModal(row, true); this.props.openModal("editBrandModal") }}>{this.props.t('common.buttons.edit')}</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => <button className="px-3 p-1 bg-red-50" onClick={(e) => this.props.removeRow("brands", row)}><FontAwesomeIcon icon={['fas', 'trash']} className="text-red-500" /></button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]

    /**
     * When the user clicks on the Commercial Menu, the state of the Commercial Menu is set to true.
     * @param value - true or false
     */
    handleCommercialMenu(value) {
        this.setState({ isActiveCommercialMenu: value });
    }
    /**
     * Execute a prop function with params
     * @param {String} id
     * @param {String} commercialize_by
     */
    setCommercializeScreenBy = (id, commercialize_by) => {
        this.props.setCommercializeScreenBy(id, commercialize_by);
    }
    /**
     * Execute a prop function with param
     * @param {String} value
     */
    setCommercializeAllScreensBy = (value) => {
        this.props.setCommercializeAllScreensBy(value);
    }
    /**
     * Checks if "commercialize_all_by" property in user is equals to "seconds"
     * @returns {Boolean}
     */
    getComercializeStatus = (value) => this.state.user.commercialize_all_by === "seconds";
    /**
     * Get the commercialize property
     * @returns {String}
     */
    getCommercializeString = () => {
        return this.props.getCommercializeString();
    }

    render() {
        const isActiveCommercialMenu = this.state.isActiveCommercialMenu;
        // const customFilter = createFilter({
        //     ignoreCase: true,
        //     ignoreAccents: true,
        //     matchFrom: 'any',
        //     trim: true
        // });
        // const { filteredCustomers } = this.props;
        return (
            <div className="flex divide-x divide-dotted">
                <SideNavbar >
                    <nav className="flex flex-col">
                        <button id="commercialSectionPricing" onClick={() => this.handleCommercialMenu("pricing")} className={`${isActiveCommercialMenu === "pricing" ? 'tabActiveV font-semibold' : ''} tabGeneral uppercase text-xs text-right`}>
                            <span className="hidden md:inline-block">{this.props.t('sections.management.section.commercial.pricing.tab')}</span> <FontAwesomeIcon icon={["fal", "wallet"]} className="text-blue-500" fixedWidth />
                        </button>
                        <button id="commercialSectionCustomers" onClick={() => this.handleCommercialMenu("customers")} className={`${isActiveCommercialMenu === "customers" ? 'tabActiveV font-semibold truncate' : ''} tabGeneral uppercase text-xs text-right`}>
                            <span className="hidden md:inline-block">{this.props.t('sections.management.section.commercial.customers.tab')}</span> <FontAwesomeIcon icon={["fal", "user-friends"]} className="text-blue-500" fixedWidth />
                        </button>
                        <button id="commercialSectionBrands" onClick={() => this.handleCommercialMenu("brands")} className={`${isActiveCommercialMenu === "brands" ? 'tabActiveV font-semibold' : ''} tabGeneral uppercase text-xs text-right`}>
                            <span className="hidden md:inline-block">{this.props.t('sections.management.section.commercial.brands.tab')}</span>  <FontAwesomeIcon icon={["fal", "tags"]} className="text-blue-500" fixedWidth />
                        </button>
                    </nav>
                </SideNavbar>
                <div className="px-4 w-full md:w-10/12 pb-10">
                    <div className={`${isActiveCommercialMenu === "pricing" ? "block" : "hidden"} w-full`}>
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            {this.props.t('sections.management.section.commercial.pricing.priceTitle')}
                            <p className="font-light">{this.props.t('sections.management.section.commercial.pricing.priceTxt')}</p>
                                <p>{this.props.t('sections.management.section.commercial.pricing.priceTable.screensPricePer')}</p>
                            <div className="mx-2 px-2 w-24">
                                <ButtonSwitcher handleVisibility={()=>{this.setCommercializeAllScreensBy(this.getCommercializeString())}} status={this.getComercializeStatus(true)} labelOn={this.props.t('sections.management.section.commercial.pricing.priceTable.second')} labelOff={this.props.t('sections.management.section.commercial.pricing.priceTable.impression')} revertLabel={true} className="absolute left-0" />
                            </div>
                        </label>
                        <div className="w-full overflow-y-auto max-h-96">
                            <DataTable
                                id="dataTable"
                                className="w-full m-0 p-0"
                                responsive={true}
                                dense={true}
                                columns={this.commercialScreenPricing}
                                noHeader={true}
                                data={
                                    this.state.screens
                                }
                            />
                        </div>
                    </div>
                    <div className={`${isActiveCommercialMenu === "customers" ? "block" : "hidden"}`}>
                        <div className="flex justify-between">
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    {this.props.t('sections.management.section.commercial.customers.customerTitle')}
                                    <p className="font-light">{this.props.t('sections.management.section.commercial.customers.customerTxt')}</p>
                                </label>
                            </div>
                            <div>
                                <button className="group buttonNew" onClick={(e) => this.props.openModal("createCustomerModal")}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="text-white" onClick={(e) => this.props.openModal("createCustomerModal")} />
                                    <span className="hidden group-hover:inline opacity-0 transition-opacity delay-300 group-hover:opacity-100 ml-4 font-light" onClick={(e) => this.props.openModal("createCustomerModal")}>{this.props.t('sections.management.section.commercial.customers.createButton')}</span>
                                </button>
                            </div>
                        </div>
                        <div className="w-full relative overflow-y-auto max-h-96">
                            <DataTable
                                className="w-full"
                                responsive={true}
                                dense={true}
                                keyField="_id"
                                noDataComponent={false}
                                columns={this.commercialCustomersList}
                                noHeader={true}
                                data={
                                    this.state.customers
                                }
                            />
                        </div>
                    </div>
                    <div className={`${isActiveCommercialMenu === "brands" ? "block" : "hidden"} overflow-y-auto`}>
                        <div className="flex justify-between">
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    {this.props.t('sections.management.section.commercial.brands.brandTitle')}
                                    <p className="font-light">{this.props.t('sections.management.section.commercial.brands.brandTxt')}</p>
                                </label>
                            </div>
                            <div>
                                <button className="group buttonNew" onClick={(e) => this.props.openModal("createBrandModal")}>
                                    {/* Abrir modal para dar de alta Nueva Brand
                                        - Nombre de Marca
                                        - DropDown de Customer
                                    */}
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="text-white" onClick={(e) => this.props.openModal("createBrandModal")} />
                                    <span className="hidden group-hover:inline opacity-0 transition-opacity delay-300 group-hover:opacity-100 ml-4 font-light" onClick={(e) => this.props.openModal("createBrandModal")}>{this.props.t('sections.management.section.commercial.brands.createButton')}</span>
                                </button>
                            </div>
                        </div>
                        <div className="w-full overflow-y-auto h-full pb-10">
                            <DataTable
                                className="w-full"
                                responsive={true}
                                dense={true}
                                keyField="_id"
                                noDataComponent={false}
                                columns={this.commercialBrandList}
                                noHeader={true}
                                data={
                                    this.state.brands
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(CommercialLayout);
