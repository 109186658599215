import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import Title from '../common/Title';

class FinanceLayout extends Component {
    constructor(props){
        super(props);
        this.state = {
            currency: [],
            actualCurrency: ''
        };
    }

    componentDidMount = () => {
        this.setState({
            currency: this.props.currency
        })
    }

    componentDidUpdate = ( prevProps, prevState) => {
        if(prevProps.actualCurrency !== this.props.actualCurrency){
            this.setState({
                actualCurrency: this.props.actualCurrency
            })
        }
    }
    render() {
        return (
            <div>
                <Title>
                    <FontAwesomeIcon icon={["fal", "coin"]} fixedWidth className="text-blue-500" /> {this.props.t('sections.management.section.finance.currencyTitle')}
                    <p className="font-light">{this.props.t('sections.management.section.finance.currencyTxt')}</p> 
                </Title>
                <div className="my-2 md:w-2/6">
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <select onChange={this.props.handleOnChangeCurrency} tabIndex="2" className="block text-gray-700 w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring">
                                <option value="-1">{this.props.t('sections.management.section.finance.currencySelect')}</option>
                                {this.state.currency.map((curr, i) => (
                                    (this.state.actualCurrency === curr.ISO
                                    ?  <option selected value={curr.ISO} key={i}>{curr.symbol} {curr.ISO} - {curr.name}</option>
                                    :  <option value={curr.ISO} key={i}>{curr.symbol} {curr.ISO} - {curr.name}</option>
                                    )
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(FinanceLayout);